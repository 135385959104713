import React, { useEffect } from "react";
import '../assets/css/Home.css';
import Navbar from '../components/Navbar';
import TechStack from '../components/TechStack';
import { ProjectCard } from "../components/Cards/Projects";
import { ExperienceCard } from "../components/Cards/Experience";
import { YoutubeCard } from "../components/Cards/Youtube";
import { ContactSection } from "../components/Cards/ContactSection";
import { Testimonial } from "../components/Cards/Testimonials";
import StarCounter from "../components/StarCounter";
import TwitterIcon from "../assets/images/Twitter.png";
import WordleIcon from "../assets/images/Wordle.png";
import PanCardIcon from "../assets/images/PanCard.png";
import WeatherIcon from "../assets/images/Weather.png";
import ShipProIcon from "../assets/images/ShipPro.png";
import AcademiaIcon from "../assets/images/Academia.png";
import FaceDetectionIcon from "../assets/images/FaceDetection.png";
import VegetableIcon from "../assets/images/Whitebasket.png";
import OGIcon from "../assets/images/og_ideology.png"
import CalcIcon from "../assets/images/Calc.png";
import AlgoNinjasIcon from "../assets/images/Alg.svg";
import MLSLogoIcon from "../assets/images/mlslogo.png";
import GEUIcon from "../assets/images/GEU.png";
import ProYouthIcon from "../assets/images/ProYouth.png";
import AccentureIcon from "../assets/images/Accenture.png";
import InLabsIcon from "../assets/images/InLabs.png";
import Portrait from "../assets/images/Portrait.png";
import { useTheme } from "../components/ThemeContext";
import styled from "styled-components";
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
//Youtube Images
import BurntIcon from "../assets/images/Burnt.png";
import CutThroat from "../assets/images/CutThroat.jpeg";
import Running from "../assets/images/running.png";
import SinCity from "../assets/images/sinCity.png";
//Testimonial Images
import Bhakti from "../assets/images/Testimonials/Bhakti.png";
import PB from "../assets/images/Testimonials/PB.png";
import Mayank from "../assets/images/Testimonials/Mayank.png";
import Ritik from "../assets/images/Testimonials/Ritik.png";
import Aryan from "../assets/images/Testimonials/Aryan.png";
import Kartik from "../assets/images/Testimonials/Kartikey.png";
import Apoorva from "../assets/images/Testimonials/Apoorva.png";
import Viraat from "../assets/images/Testimonials/Viraat.png";
import Lamha from "../assets/images/Testimonials/Lamha.png";
import Musaib from "../assets/images/Testimonials/musaib.png";
const Button = styled.button`
  padding: 10px 16px;
  background-color: #fbbe01;
  width:200px;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  border: 3px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 8px;
  &:hover {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fbbe01;
  }
`;

function Home() {
    const { theme, toggleTheme } = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = (event) => {
            // Change the value of scrollBy according to your desired scrolling speed
            window.scrollBy(0, event.deltaY * 1.1); // You can adjust the multiplier to control the scroll speed
        };

        // Add event listener to listen for mouse scroll events
        window.addEventListener('wheel', handleScroll, { passive: false });

        // Remove event listener when the component is unmounted
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show');
                }
            });
        });

        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));

        // Cleanup function
        return () => {
            hiddenElements.forEach((el) => observer.unobserve(el));
        };
    }, []);

    const handleClick = () => {
        navigate("/");
    }

    return (
        <div className={`home-container ${theme}`} id="Starting" style={{ overflow: 'hidden' }}>
            <Navbar className="site-navbar" theme={theme} />
            <div className='lightDarkToggleSwitch'>
                <label className="ldts">
                    <input type='checkbox' onClick={toggleTheme} />
                    <span className='slider'></span>
                </label>
            </div>
            <div className={`starsClass ${theme}`} id="stars"></div>
            <div className={`starsClass ${theme}`} id="stars2"></div>
            <div className={`starsClass ${theme}`} id="stars3"></div>
            {/* <h1>Aditya Bahl</h1> */}
            <div className="hero_name">
                <h1 >Aditya Bahl</h1>
                {/* <h1 className="hero_name_item2">Bahl</h1> */}
            </div>
            <img className="hero_image" src={Portrait} alt="Potrait of Aditya Bahl" />
            {/* <h1 id="AboutSection" >About</h1> */}
            {/* <div className="about_section" style={{ height: '100vh' }}></div> */}
            <div className="hidden">
                <div id="AboutSection" className="about_section">
                    <h1 className="about_section_h1">
                        Aditya Bahl, the tech enthusiast behind the screen, weaves a dynamic story of
                        innovation from the heart of Dehradun, India. Unveiling his global journey,
                        Aditya's address is a vibrant hub for creativity. Aditya's academic canvas
                        showcases a colorful blend of technical mastery and creative expression.
                        Aditya's certifications and achievements sparkle, adding glitter to his tech
                        saga. Ready for more? Dive into his world!
                    </h1>
                </div>
            </div>
            <h1 className="tchStck">Tech Stack</h1>
            <div className="hidden">
                <TechStack />
            </div>
            <div className="hidden">
                <h1 id="ExperienceSection">Experience</h1>
                <div className="horizontal-scroll-container">
                    <ExperienceCard className="horizontal-scroll-item" name={"Accenture"} role={" Packaged App Development Associate"} date={"(Feb 2024 - Till Date)"} button2={"Visit"} link1={"https://drive.google.com/drive/folders/1gcgYudt5Qg0m_6myskfn6iJ07nkJvvwI?usp=sharing"} link2={"https://www.accenture.com/in-en"} desc1={"Undergoing training phase, Freshly Joined."} desc2={"Stay Tuned for more Updates!"} img={AccentureIcon} />
                    <ExperienceCard className="horizontal-scroll-item" name={"InLabs Global"} role={"Software Engineering Intern"} date={"(Dec 2023 - April 2024)"} button2={"See Work"} link1={"https://github.com/AdityaBahl/InLabsGlobal/"} link2={"https://inlabsglobal.com/"} desc1={"Developed a marketplace product using Flutter."} desc2={"Utilised various backend and frontend technologies"} img={InLabsIcon} />
                    <ExperienceCard className="horizontal-scroll-item" name={"AlgoNinjas"} role={"Software Engineering Intern"} date={"(Sept 2023 - Nov 2023)"} button2={"See Work"} link1={"https://drive.google.com/drive/folders/1o2kaVXc_L7NgfhR6g9Ldd-0ZR9dInbJH"} link2={"https://algoninjas.com/"} desc1={"Developed the product and the main website using MERN."} desc2={"Utilised SDLC, DevOps and Cloud Computing."} img={AlgoNinjasIcon} />
                    <ExperienceCard className="horizontal-scroll-item" name={"Graphic Era University"} role={"Full Stack Web Development Intern"} date={"(July 2023 - Sept 2023)"} button2={"See Work"} link1={"https://drive.google.com/file/d/1NEwCGspnsVMfXpmW3tcvypNjKC_PrRew/view?usp=sharing"} link2={"https://grocery-store-frontend-gamma.vercel.app/"} desc1={"B2C Marketplace Platform made using MERN."} desc2={"Part of a team of four members."} img={GEUIcon} />
                    <ExperienceCard className="horizontal-scroll-item" name={"MLS Classes"} role={"Full Stack Web Development Intern"} date={"(June 2023 - Aug 2023)"} button2={"See Work"} link1={"https://drive.google.com/drive/folders/1ZCKfskE1rQ9FoJwkCpp7lS8Fz1OISlzq?usp=sharing"} link2={"https://mlsclasses.com/"} desc1={"Developed the main website with SEO Optimisation."} desc2={"Utilised Cloud Computing and HubSpot(CRM)."} img={MLSLogoIcon} />
                    <ExperienceCard className="horizontal-scroll-item" name={"ProYouth.in"} role={"Front End Web Development Intern"} date={"(Feb 2023 - May 2023)"} button2={"See Work"} link1={"https://drive.google.com/drive/folders/1MubHYG64JYVJTo2rsKJzDVQ4ythERnqt?usp=sharing"} link2={"https://proyouth.in/"} desc1={"Migrated the website from Legacy technology to React."} desc2={"Hands-on experience with SEO optimisations on a website."} img={ProYouthIcon} />
                </div>
            </div>
            <div className="hidden">
                <h1 id="ProjectSection">Projects</h1>
                <div className="horizontal-scroll-container">
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"SigmaMandi"} date={"(July 2023 - Sept 2023)"} button2={"Deployed"} link1={"https://github.com/AdityaBahl/Vegetable-Marketplace"} link2={"https://grocery-store-frontend-gamma.vercel.app/"} desc1={"B2C Vegetable Marketplace Platform made using MERN."} desc2={"Part of a team of four members."} img={VegetableIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Face Detection Application"} date={"(Jan 2023 - March 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Face-Detection-using-OpenCV"} link2={"https://github.com/AdityaBahl/Face-Detection-using-OpenCV/blob/master/README.md"} desc1={"Detects faces Using CNN with 3 different modes."} desc2={"Mentored Junior Students on ML with this project."} img={FaceDetectionIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Academia"} date={"(Apr 2023 - June 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Student-Record-Maintenance-System-Web-App"} link2={"https://github.com/AdityaBahl/Student-Record-Maintenance-System-Web-App/blob/main/README.md"} desc1={"Student Record Maintenance System."} desc2={"CRUD Web Software made using PHP, MySQL."} img={AcademiaIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"ShipPro"} date={"(July 2023 - Sept 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Courier-Management-System"} link2={"https://github.com/AdityaBahl/Courier-Management-System/blob/main/Report_CourierManagementSystem_6th_sem.pdf"} desc1={"Courier Management System using MERN stack."} desc2={"Covers all aspects of courier delivery lifecycle."} img={ShipProIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Disaster Buddy 🤖"} date={"(July 2023 - Sept 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Disaster-Management-System"} link2={"https://github.com/AdityaBahl/Disaster-Management-System/blob/main/README.md"} desc1={"Disaster Management System using MERN stack."} desc2={"Weather forecast, prediction using ML, news updates, tips."} img={WeatherIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Pan Card Authenticator"} date={"(July 2023 - Sept 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Pan-Card-Image-Tampering-Detection-Flask-App"} link2={"https://github.com/AdityaBahl/Pan-Card-Image-Tampering-Detection-Flask-App/blob/main/README.md"} desc1={"Pan Card Tampering Detection Application."} desc2={"Machine Learning application with a flask UI."} img={PanCardIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Wordle Game"} date={"(July 2023 - Sept 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Wordle-Game"} link2={"https://github.com/AdityaBahl/Wordle-Game/blob/main/README.md"} desc1={"The famous Wordle game using MERN stack."} desc2={"Unique for it's unlimited usage per day for aficionados."} img={WordleIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Twitter Sentimental Analysis"} date={"(July 2023 - Sept 2023)"} button2={"Documentation"} link1={"https://github.com/AdityaBahl/Twitter_Sentimental_Analysis"} link2={"https://github.com/AdityaBahl/Twitter_Sentimental_Analysis/blob/main/Readme.md"} desc1={"Analyzes twitter sentiments based on latest Twitter data."} desc2={"Machine Learning Application, Utilizes Twitter API."} img={TwitterIcon} />
                    <ProjectCard style={{ opacity: "1" }} className="horizontal-scroll-item" name={"Android Calculator"} date={"(July 2023 - Sept 2023)"} button2={"Install"} link1={"https://github.com/AdityaBahl/Calculator-Android-App"} link2={"https://github.com/AdityaBahl/Calculator-Android-App/blob/main/app/release/app-release.apk"} desc1={"Basic Android Calculator made using Kotlin and gradle."} desc2={"Installed and used by over 50+ people for its simplicity."} img={CalcIcon} />
                </div>
            </div>
            <div style={{ zIndex: "-1" }} className={`starsClass ${theme}`} id="stars"></div>
            <div style={{ zIndex: "-1" }} className={`starsClass ${theme}`} id="stars2"></div>
            <div style={{ zIndex: "-1" }} className={`starsClass ${theme}`} id="stars3"></div>
            <div className="hidden">
                <h1 className="yt">Youtube</h1>
                <h3 className="ytText">I occasionally rap, here is some of my work, hope you enjoy it!</h3>
                <div className="horizontal-scroll-container">
                    <YoutubeCard className="horizontal-scroll-item" name={"Cut Throat"} link={"https://youtu.be/QMnSfOTshog?si=SzHe6ESZyAdgf8ES"} desc={"My Personal Favourite track."} img={CutThroat} />
                    <YoutubeCard className="horizontal-scroll-item" name={"Burnt"} link={"https://youtu.be/vHOWo_D7rag?si=pbNC1fT5PFWmLemL"} desc={"My first decent try at beatmaking."} img={BurntIcon} />
                    <YoutubeCard className="horizontal-scroll-item" name={"Sin City"} link={"https://youtu.be/Iwm6R7Dyy2c?si=5sYBjnIWiOHgXSqL"} desc={"Lofi track, commentary on society."} img={SinCity} />
                    <YoutubeCard className="horizontal-scroll-item" name={"Running"} link={"https://youtu.be/og0wvez-qPo?si=Ldq90EPCYMmxZMfk"} desc={"My first track on YouTube. EVER."} img={Running} />
                    <YoutubeCard className="horizontal-scroll-item" link={"https://www.youtube.com/@ogideology"} name={"Check out for more..."} img={OGIcon} />
                </div>
            </div>
            <div>
                <h1 className="test">Testimonial</h1>
                <div className="hidden">
                    <div className="horizontal-scroll-container">
                        <Testimonial name={"Ritik Mittal"} role={"Founder of AlgoNinjas"} desc1={"I am confident that Aditya would be an asset to any "} desc2={" team. He is a highly skilled and experienced "} desc3={" developer with a strong work ethic and a positive "} desc4={" attitude. I highly recommend him and wish him all"} desc5={" the best."} img={Ritik} />
                        <Testimonial name={"Rahul Banarjee"} role={"Founder of ProYouth.in"} desc1={"Aditya consistently exceeded expectations, showing "} desc2={"sharp analytical skills and an ability to find innovative"} desc3={" solutions, possessing exceptional interpersonal skills,"} desc4={" he fostered a positive work environment. His attention "} desc5={"to detail and time management was commendable."} img={ProYouthIcon} />
                        <Testimonial name={"James"} role={"Founder of MLS Classes"} desc1={"I am confident that Aditya would be an asset to any "} desc2={" team. He is a highly skilled and experienced "} desc3={" developer with a strong work ethic and a positive "} desc4={" attitude. I highly recommend him and wish him all"} desc5={" the best."} img={MLSLogoIcon} />
                        <Testimonial name={"Aryan Yadav"} role={"UI/UX designer"} desc1={"Aditya is always eager to challenge the unknown and "} desc2={"does not let technical barriers cage his potential. I  "} desc3={"can easily recommend him to anyone seeking a "} desc4={" talented developer who pushes boundaries."} img={Aryan} />
                        <Testimonial name={"Kartikeya Saxena"} role={"Software Engineer"} desc1={"I know Aditya as an excellent developer who's always "} desc2={" keen on taking intiatives to solve problems "} desc3={" irrespective of however complex they are, be it "} desc4={" projects, queries and or workflow optimisation."} img={Kartik} />
                        <Testimonial name={"Apoorva Priya"} role={"Software Engineer at Genpact"} desc1={"I've had the pleasure of knowing Aditya both "} desc2={"personally and professionally and can confidently say"} desc3={" he's an exceptional software engineer. His dedication "} desc4={"to work is evident in the quality of work he produces,"} desc5={" always striving for excellence."} img={Apoorva} />
                        <Testimonial name={"Pratyaksh Bharadwaj"} role={"Software Engineer at Salesforce"} desc1={"I’ve worked very closely with Aditya, and can tell he’s"} desc2={" one of the most hardworking student that strives for"} desc3={" perfection in everything he does."} img={PB} />
                        <Testimonial name={"Viraat Kumar"} role={"Software Engineer"} desc1={"During our web development internship, I closely "} desc2={"collaborated with Aditya, a dedicated developer who"} desc3={" effectively tackled challenges. His passion and "} desc4={"positive attitude greatly benefited our team."} img={Viraat} />
                        <Testimonial name={"Bhakti Vaidya"} role={"Software Engineer"} desc1={"Aditya Bahl's dedication and collaborative spirit at "} desc2={"MLS Classes were inspiring. His meticulous problem-"} desc3={"solving and innovative ideas greatly enhanced our "} desc4={"team's productivity. A true asset, he consistently"} desc5={" delivered excellence."} img={Bhakti} />
                        <Testimonial name={"Muhammad Musaib"} role={"Software Engineer"} desc1={"Aditya, The name shall always remind me of proactive"} desc2={" also he is go-getter. His attitude toward work is nice"} desc3={" & calm. I personally worked with him on a project"} desc4={" such a idealistic person to have as a team. He is calm "} desc5={"and composed and is a very accessible person."} img={Musaib} />
                        <Testimonial name={"Lamha Chaturvedi"} role={"UI/UX designer"} desc1={"Working alongside Aditya Bahl as a web developer "} desc2={"was an absolute pleasure.Not once did he hesitate to"} desc3={"accommodate revisions, always striving to exceed"} desc4={"expectations. I highly recommend Aditya Bahl for his"} desc5={" exceptional skills and professionalism."} img={Lamha} />
                        <Testimonial name={"Mayank Kumar"} role={"ML Engineer at starX91"} desc1={"Aditya is a passionate programmer who constantly"} desc2={" seeks ways to overcome problems and can come up "} desc3={"with solutions for semester projects, full-stack"} desc4={" development queries or resume building."} img={Mayank} />
                    </div>
                </div>
            </div>
            <div className="hidden">
                <h1 id="ContactSection" style={{ marginTop: '10%', marginBottom: '-10%' }}></h1>
                <ContactSection />
            </div>
            <div className="ThnkU">
                <p>Thank You ⚡</p>
            </div>
            <div style={{ zIndex: "9999999", marginTop: "25%", opacity: "1", marginLeft: "35.5%" }}>
                <video
                    className="auth-sidebar-video"
                    playsInline
                    autoPlay
                    loop
                    muted
                    src="https://cdn.dribbble.com/users/721278/screenshots/17054114/media/c008d6b88fda777ef76b06a56679cadd.mp4"
                    width="45%" // Adjust width as needed
                    height="50%" // Adjust height as needed
                // Add controls for manual play/pause/etc. if needed
                >
                    Your browser does not support the video tag.
                </video>
            </div>
            {/* <YoutubeCard className="horizontal-scroll-item" /> */}
            <div >
                <ScrollLink className="BckToTop" to="Starting" smooth={true} duration={1000}>
                    <Button style={{ marginTop: '30%' }}>👆🏼</Button>
                </ScrollLink>
            </div>
        </div>
    );
}

export default Home;
