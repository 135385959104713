import React, { useState } from "react";
import logo from '../assets/images/white.svg';
import logo2 from '../assets/images/black.svg';
import styled from 'styled-components';
// import dp from '../assets/img/Frame.svg';
import '../assets/css/Navbar.css';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
function Navbar(props) {
    const resumeLink = "https://drive.google.com/file/d/1rqoFxs5jYfHvzc7COR52EfkSaWKA_mDt/view?usp=sharing";
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
    }
    const StyledComponents = styled.div`
    .nav{
        height: 60px;
        z-index: 1000;
    }
    .dark{
        background-color: black;
        color:white;
    }
    .light{
        background-color: white;
        color:black;
    }
    .light:hover{
        color:#a8a807;
    }
    .dark:hover{
        color:#a8a807;
    }
    .QuickAccess_items_dark{
        background-color: black;
    }
    @media (max-width: 768px){
        .dark{
            color: black;
        }
        .light{
            color: white;
        }
    }
    `;
    return (
        <StyledComponents>
            <div className={`nav ${props.theme}`}>
                <img src={props.theme === "dark" ? logo : logo2} onClick={handleClick} className="nav-logo" alt="Logo" />
                <div className="QuickAccess">
                    <ScrollLink className={`QuickAccess_items ${props.theme}`} to="AboutSection" smooth={true} duration={1000}>
                        About
                    </ScrollLink>
                    <ScrollLink className={`QuickAccess_items ${props.theme}`} to="ExperienceSection" smooth={true} duration={1000}>
                        Experience
                    </ScrollLink>
                    <ScrollLink className={`QuickAccess_items ${props.theme}`} to="ProjectSection" smooth={true} duration={1500}>
                        Projects
                    </ScrollLink>
                    <ScrollLink className={`QuickAccess_items ${props.theme}`} to="ContactSection" smooth={true} duration={2500}>
                        Contact
                    </ScrollLink>
                    <a className={`QuickAccess_items ${props.theme}`} href={resumeLink} target="_blank" rel="noopener noreferrer">Resumé ↗</a>
                </div>
            </div>
        </StyledComponents>
    );
}
export default Navbar;