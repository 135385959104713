import React, { Suspense, useState } from "react";
//websites for badges:
// https://dev.to/envoy_/150-badges-for-github-pnk#skills
// http://envoy1084.me/awesome-badges/#-os
const Python = "https://img.shields.io/badge/Python-3776AB?style=for-the-badge&logo=python&logoColor=white";
const CPP = "https://img.shields.io/badge/C%2B%2B-00599C?style=for-the-badge&logo=c%2B%2B&logoColor=white";
const JS = "https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=JavaScript&logoColor=white";
const Java = "https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=openjdk&logoColor=white";
const R = "https://img.shields.io/badge/R-276DC3?style=for-the-badge&logo=r&logoColor=white";
const Kotlin = "https://img.shields.io/badge/Kotlin-0095D5?&style=for-the-badge&logo=kotlin&logoColor=white";
const MD = "https://img.shields.io/badge/Markdown-000000?style=for-the-badge&logo=markdown&logoColor=white";
//CLoud
const GCP = "https://img.shields.io/badge/Google_Cloud-4285F4?style=for-the-badge&logo=google-cloud&logoColor=white";
const AWS = "https://img.shields.io/badge/Amazon_AWS-FF9900?style=for-the-badge&logo=amazonaws&logoColor=white";
const Heroku = "https://img.shields.io/badge/Heroku-430098?style=for-the-badge&logo=heroku&logoColor=white";
const Salesforce = "https://img.shields.io/badge/Salesforce-00A1E0?style=for-the-badge&logo=Salesforce&logoColor=white";
const Netlify = "https://img.shields.io/badge/Netlify-00C7B7?style=for-the-badge&logo=netlify&logoColor=white";
// const Azure = "https://img.shields.io/badge/microsoft%20azure-0089D6?style=for-the-badge&logo=microsoft-azure&logoColor=white";
const Cloudflare = "https://img.shields.io/badge/Cloudflare-F38020?style=for-the-badge&logo=Cloudflare&logoColor=white";
const GitAct = "https://img.shields.io/badge/GitHub_Actions-2088FF?style=for-the-badge&logo=github-actions&logoColor=white";
//Frameworks
const Jenkins = "https://img.shields.io/badge/Jenkins-D24939?style=for-the-badge&logo=Jenkins&logoColor=white";
const Docker = "https://img.shields.io/badge/docker-%230db7ed.svg?style=for-the-badge&logo=docker&logoColor=white";
const Kubernetes = "https://img.shields.io/badge/kubernetes-%23326ce5.svg?style=for-the-badge&logo=kubernetes&logoColor=white";
const Gradle = "https://img.shields.io/badge/Gradle-02303A.svg?style=for-the-badge&logo=Gradle&logoColor=white";
const MySQL = "https://img.shields.io/badge/MySQL-00000F?style=for-the-badge&logo=mysql&logoColor=white";
//Web
const Mongo = "https://img.shields.io/badge/MongoDB-4EA94B?style=for-the-badge&logo=mongodb&logoColor=white";
const Express = "https://img.shields.io/badge/Express.js-404D59?style=for-the-badge";
const Reactjs = "https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB";
const Nodejs = "https://img.shields.io/badge/Node.js-43853D?style=for-the-badge&logo=node.js&logoColor=white";
const Django = "https://img.shields.io/badge/Django-092E20?style=for-the-badge&logo=django&logoColor=white";
const HTML = "https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white";
const CSS = "https://img.shields.io/badge/CSS3-1572B6?style=for-the-badge&logo=css3&logoColor=white";
const PHP = "https://img.shields.io/badge/PHP-777BB4?style=for-the-badge&logo=php&logoColor=white";
//Coding Platforms
const Codeforces = "https://img.shields.io/badge/Codeforces-445f9d?style=for-the-badge&logo=Codeforces&logoColor=white";
const Codechef = "https://img.shields.io/badge/Codechef-%23B92B27.svg?&style=for-the-badge&logo=Codechef&logoColor=white";
const Hackerearth = "https://img.shields.io/badge/HackerEarth-%232C3454.svg?&style=for-the-badge&logo=HackerEarth&logoColor=Blue";
const Hackerrank = "https://img.shields.io/badge/-Hackerrank-2EC866?style=for-the-badge&logo=HackerRank&logoColor=white";
const Leetcode = "https://img.shields.io/badge/-LeetCode-FFA116?style=for-the-badge&logo=LeetCode&logoColor=black";
const Stackoverflow = "https://img.shields.io/badge/Stack_Overflow-FE7A16?style=for-the-badge&logo=stack-overflow&logoColor=white";
//OS
const Windows = "https://img.shields.io/badge/Windows-0078D6?style=for-the-badge&logo=windows&logoColor=white";
const Ubuntu = "https://img.shields.io/badge/Ubuntu-E95420?style=for-the-badge&logo=ubuntu&logoColor=white";
const MacOS = "https://img.shields.io/badge/mac%20os-000000?style=for-the-badge&logo=apple&logoColor=white";
const Linux = "https://img.shields.io/badge/Linux-FCC624?style=for-the-badge&logo=linux&logoColor=black";
const Android = "https://img.shields.io/badge/Android-3DDC84?style=for-the-badge&logo=android&logoColor=white";
//Terminals
const Git = "https://img.shields.io/badge/GIT-E44C30?style=for-the-badge&logo=git&logoColor=white";
const Hyper = "https://img.shields.io/badge/Hyper-000000?style=for-the-badge&logo=hyper&logoColor=white";
const Powershell = "https://img.shields.io/badge/powershell-5391FE?style=for-the-badge&logo=powershell&logoColor=white";
const WinTerm = "https://img.shields.io/badge/windows%20terminal-4D4D4D?style=for-the-badge&logo=windows%20terminal&logoColor=white";
const GNUBash = "https://img.shields.io/badge/GNU%20Bash-4EAA25?style=for-the-badge&logo=GNU%20Bash&logoColor=white";
function TechStack() {
    return (
        <div>
            <div>
                <h2>Language</h2>
                <img src={CPP} alt="C++" />
                <img src={JS} alt="JavaScript" />
                <img src={Java} alt="Java" />
                <img src={Python} alt="Python" />
                <img src={R} alt="R" />
                <img src={Kotlin} alt="Kotlin" />
                <img src={MD} alt="MarkDown" />
            </div>
            <div>
                <h2>Tools and platforms</h2>
                <img src={Jenkins} alt={"Jenkins"} />
                <img src={Docker} alt={"Docker"} />
                <img src={Kubernetes} alt={"Kubernetes"} />
                <img src={Gradle} alt={"Gradle"} />
                <img src={MySQL} alt={"MySQL"} />
            </div>
            <div>
                <h2>Cloud</h2>
                <img src={GCP} alt={"GCP"} />
                <img src={AWS} alt={"AWS"} />
                <img src={GitAct} alt={"GitAct"} />
                <img src={Salesforce} alt={"Salesforce"} />
                <img src={Heroku} alt={"Heroku"} />
                <img src={Netlify} alt={"Netlify"} />
                <img src={Cloudflare} alt={"Cloudflare"} />
            </div>
            <div>
                <h2>Web</h2>
                <img src={Mongo} alt={"Mongo"} />
                <img src={Express} alt={"Express"} />
                <img src={Reactjs} alt={"Reactjs"} />
                <img src={Nodejs} alt={"Nodejs"} />
                <img src={Django} alt={"Django"} />
                <img src={HTML} alt={"HTML"} />
                <img src={CSS} alt={"CSS"} />
                <img src={JS} alt={"JS"} />
                <img src={PHP} alt={"PHP"} />
            </div>
            <div>
                <h2>Coding Platforms</h2>
                <img src={Leetcode} alt={"Leetcode"} />
                <img src={Codeforces} alt={"Codeforces"} />
                <img src={Hackerrank} alt={"Hackerrank"} />
                <img src={Hackerearth} alt={"Hackerearth"} />
                <img src={Codechef} alt={"Codechef"} />
                <img src={Stackoverflow} alt={"Stackoverflow"} />
            </div>
            <div>
                <h2>OS</h2>
                <img src={Windows} alt={"Windows"} />
                <img src={Ubuntu} alt={"Ubuntu"} />
                <img src={Linux} alt={"Linux"} />
                <img src={Android} alt={"Android"} />
                <img src={MacOS} alt={"MacOS"} />
            </div>
            <div>
                <h2>Terminals</h2>
                <img src={Git} alt={"Git"} />
                <img src={Hyper} alt={"Hyper"} />
                <img src={Powershell} alt={"Powershell"} />
                <img src={WinTerm} alt={"Windows Terminal"} />
                <img src={GNUBash} alt={"GNU Bash"} />
            </div>
        </div>
    );
}
export default TechStack;
