import React from "react";
import styled from "styled-components";
import { Marginer } from "../../marginer";
import { useNavigate } from 'react-router-dom';
import Verify from "../../../assets/images/Verify.svg";

const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5em 6px 0 6px;
  line-height: 1.4;
  margin-top:-10%;
`;

const MediumText = styled.span`
  font-size: 18px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
`;
const MediumTextRole = styled.span`
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  // text-transform: uppercase;
`;

const SmallText = styled.span`
  // margin-top:-50px;
  font-size: 11px;
  color: #fff;
  // max-width:400px;
  word-wrap:break-word;
  // white-space:nowrap;
  // overflow:hidden;
  // text-overflow:ellipsis;
  font-weight: 500;
  text-transform: uppercase;
  line-height:25px;
`;

const SpacedHorizontalContainer = styled.div`
  width: 100%;
  height:20%;
  // background-color:green;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function CardDetails(props) {
  return (
    <DetailsContainer>      
      <div style={{"height":"100px"}}>
      <p>{props.desc1}</p>
      <p style={{"marginTop":"-20px"}}>{props.desc2}</p>
      <p style={{"marginTop":"-20px"}}>{props.desc3}</p>
      <p style={{"marginTop":"-20px"}}>{props.desc4}</p>
      <p style={{"marginTop":"-20px"}}>{props.desc5}</p>
     { console.log(props.desc3)}
      </div>
      {/* <SpacedHorizontalContainer>
        <SmallText>{props.descc}</SmallText>
      </SpacedHorizontalContainer> */}
      <MediumText style={{"marginTop":"20px"}}>{props.name}</MediumText>
      <MediumTextRole>{props.role}</MediumTextRole>
      <MediumTextRole>{props.role2}</MediumTextRole>
    </DetailsContainer>
  );
}
