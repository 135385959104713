import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import { ThemeProvider } from './components/ThemeContext';

function App() {
  // const navigate = 0;

  // useEffect(() => {
  //   // Check if the screen width is less than a certain threshold (considered as mobile)
  //   const isMobile = window.innerWidth < 768;
  //   if (isMobile) {
  //     // Redirect to /home if it's a mobile device
  //     navigate("/home");
  //   }
  // }, [navigate]);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ThemeProvider>
              <Welcome />
            </ThemeProvider>
          }
        />
        <Route
          path="/home"
          element={
            <ThemeProvider>
              <Home />
            </ThemeProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
