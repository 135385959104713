import React, { useEffect, useState, Suspense } from "react";
import glogo from "../assets/images/white.svg";
import { useNavigate } from 'react-router-dom';
import "../assets/css/Welcome.css";
import Explore from "../components/Explore";
import styled, { ThemeProvider } from 'styled-components';

// const StyledContainer = styled.div`
// .background{
//   background-color: black;
//   width: 100%;
//   height: 100%;
//   overflow:hidden !important;
// }
// :root{
//   --stars:white;
//   --star_bg:black;
// }
// @keyframes animStar {
//   from {
//     transform: translateY(0px);
//   }
//   to {
//     transform: translateY(-2000px);
//   }
// }

// .img-404 {
//   filter: invert(100%) brightness(120%);
// }

// a {
//   color: #fdcc52;
//   transition: all 0.35s;
//   text-decoration: none;
// }

// a:hover,
// a:focus {
//   color: #fcbd20;
//   text-decoration: none;
// }

// hr {
//   max-width: 100px;
//   margin: 25px auto 25px auto;
//   border-width: 1px;
//   border-color: rgba(34, 34, 34, 0.1);
// }
// hr.full-width {
//   max-width: 100%;
//   margin: 25px auto;
// }

// hr.light {
//   border-color: white;
// }


// img.logo {
//   height: 50px;
// }
// header.masthead,
// .jumbotron {
//   position: relative;
//   width: 100%;
//   padding-top: 150px;
//   color: white;
//   background: var(--star_bg);
//   /* background: linear-gradient(to right, black, red); */
//   background-attachment: fixed;
// }
// header.masthead .device-container .screen img {
//   border-radius: 3px;
// }

// header.masthead {
//   height: min-content;
// }
// header.not-full-height {
//   height: unset;
// }
// .buttonsIB{
//   display: flex !important;
//   justify-content: space-evenly;
//   align-items: center;
// }
// .infBtn,.bndBtn{
//   width:47% ;
// }
// @media (min-width: 992px) {
//   header.masthead {
//     height: 100vh;
//   }
//   header.not-full-height {
//     padding-top: 20vh;
//     margin-bottom: 0px;
//   }
//   header.masthead .header-content {
//     margin-bottom: 0;
//     text-align: left;
//   }
//   header.masthead .header-content h1 {
//     font-size: 45px;
//     font-weight: 800;
//   }
//   header.masthead .device-container {
//     max-width: 325px;
//   }
// }

// .hero-buttons {
//   cursor: pointer;
//   transition: 0.1s ease;
// }
// .hero-buttons.last {
//   border-color: #1adb80 !important;
//   background: #1adb80 !important;
//   display: block;
//   /* width: 250px; */
//   text-align: center;
// }

// @keyframes move_wave {
//   0% {
//     transform: translateX(0) translateZ(0) scaleY(1);
//   }
//   50% {
//     transform: translateX(-25%) translateZ(0) scaleY(0.55);
//   }
//   100% {
//     transform: translateX(-50%) translateZ(0) scaleY(1);
//   }
// }
// .waveWrapper {
//   overflow: hidden;
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   margin: auto;
// }
// .waveWrapperInner {
//   width: 100%;
//   overflow: hidden;
//   height: 100%;
//   bottom: -1px;
// }
// .bgTop {
//   margin-top: -26%;
//   opacity: 0.5;
// }
// .bgMiddle {
//   margin-top: -47%;
//   opacity: 0.75;
// }
// .bgBottom {
//   margin-bottom: 30%;
// }
// .wave {
//   position: absolute;
//   left: 0;
//   width: 200%;
//   height: 100%;
//   background-repeat: repeat no-repeat;
//   background-position: 0 bottom;
//   transform-origin: center bottom;
// }
// .waveTop {
//   background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png');
//   background-size: 50% 100px;
// }
// .waveAnimation .waveTop {
//   animation: move-wave 3s;
//   -webkit-animation: move-wave 3s;
//   -webkit-animation-delay: 1s;
//   animation-delay: 1s;
// }
// .waveMiddle {
//   background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png');
//   background-size: 50% 120px;
// }
// .waveAnimation .waveMiddle {
//   animation: move_wave 10s linear infinite;
// }
// .waveBottom {
//   background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png');
//   background-size: 50% 100px;
// }
// .waveAnimation .waveBottom {
//   animation: move_wave 15s linear infinite;
// }

// header.masthead .container {
//   z-index: 99;
//  margin-bottom: 10%;
//   position: relative;
// }

// @media (max-width: 1023px) {
//   .wave {
//     margin-top:100%;
//     position: absolute;
//     left: 0;
//     width: 700%;
//     height: 100%;
//     background-repeat: repeat no-repeat;
//     background-position: 0 bottom;
//     transform-origin: center bottom;
//   }
// }
// ul.arrow {
//   padding: 10px;
// }

// .hero-text {
//   color: #bbc;
// }

// ul li {
//   list-style: none;
//   position: relative;
//   padding: 0 0 0 20px;
// }
// ul.arrow li::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 12px;
//   height: 10px;
//   width: 10px;
//   border: 1px solid #f60;
//   border-width: 2px 2px 0 0;
//   transform: rotate(45deg);
// }
// ul.burst li::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 5px;
//   background: #f60;
//   width: 10px;
//   height: 10px;
//   text-align: center;
//   -webkit-transform: rotate(0deg);
//   -moz-transform: rotate(0deg);
//   -ms-transform: rotate(0deg);
//   -o-transform: rotate(0deg);
//   transform: rotate(0deg);
// }

// #stars {
//   width: 1px;
//   height: 1px;
//   background: transparent;
//   box-shadow: 1111px 1897px var(--stars), 3px 1002px var(--stars), 1334px 620px var(--stars),
//     1826px 1295px var(--stars), 1145px 1216px var(--stars), 146px 628px var(--stars), 1132px 434px var(--stars),
//     1946px 1510px var(--stars), 280px 554px var(--stars), 659px 530px var(--stars), 1211px 79px var(--stars),
//     1043px 441px var(--stars), 177px 847px var(--stars), 1127px 920px var(--stars), 771px 1745px var(--stars),
//     530px 1533px var(--stars), 1628px 462px var(--stars), 1437px 673px var(--stars), 457px 1567px var(--stars),
//     1776px 1136px var(--stars), 1430px 330px var(--stars), 100px 652px var(--stars), 1632px 431px var(--stars),
//     510px 684px var(--stars), 1441px 986px var(--stars), 624px 1837px var(--stars), 1006px 484px var(--stars),
//     1930px 1047px var(--stars), 1561px 57px var(--stars), 1051px 314px var(--stars), 520px 1617px var(--stars),
//     740px 1887px var(--stars), 1897px 1735px var(--stars), 1960px 173px var(--stars), 465px 172px var(--stars),
//     219px 1288px var(--stars), 119px 1571px var(--stars), 1482px 1651px var(--stars), 1870px 374px var(--stars),
//     459px 1778px var(--stars), 1859px 801px var(--stars), 1280px 376px var(--stars), 957px 1121px var(--stars),
//     1665px 1370px var(--stars), 336px 656px var(--stars), 1904px 1157px var(--stars), 1113px 1660px var(--stars),
//     1735px 1710px var(--stars), 920px 907px var(--stars), 919px 625px var(--stars), 1641px 1662px var(--stars),
//     1967px 1427px var(--stars), 66px 245px var(--stars), 536px 472px var(--stars), 1651px 796px var(--stars),
//     1949px 614px var(--stars), 1139px 827px var(--stars), 1013px 1192px var(--stars), 1688px 1438px var(--stars),
//     555px 252px var(--stars), 707px 1071px var(--stars), 428px 221px var(--stars), 13px 1211px var(--stars),
//     1030px 937px var(--stars), 498px 1605px var(--stars), 1542px 61px var(--stars), 1186px 1863px var(--stars),
//     582px 619px var(--stars), 46px 989px var(--stars), 1457px 1744px var(--stars), 537px 3px var(--stars),
//     1360px 1714px var(--stars), 245px 1393px var(--stars), 1128px 1172px var(--stars),
//     1607px 1582px var(--stars), 1166px 679px var(--stars), 725px 1414px var(--stars), 45px 1956px var(--stars),
//     130px 483px var(--stars), 1241px 1045px var(--stars), 726px 1038px var(--stars), 1191px 1697px var(--stars),
//     1706px 1819px var(--stars), 722px 1422px var(--stars), 1438px 1445px var(--stars), 693px 303px var(--stars),
//     1313px 480px var(--stars), 1660px 1988px var(--stars), 107px 1504px var(--stars), 991px 1693px var(--stars),
//     1203px 1241px var(--stars), 580px 69px var(--stars), 1116px 1098px var(--stars), 1445px 706px var(--stars),
//     1656px 460px var(--stars), 862px 141px var(--stars), 66px 1592px var(--stars), 145px 1591px var(--stars),
//     1174px 901px var(--stars), 1824px 1823px var(--stars), 1116px 829px var(--stars), 927px 1490px var(--stars),
//     524px 1078px var(--stars), 1523px 1495px var(--stars), 1277px 1122px var(--stars), 46px 1349px var(--stars),
//     1244px 654px var(--stars), 1824px 1398px var(--stars), 1597px 1389px var(--stars), 896px 965px var(--stars),
//     584px 1319px var(--stars), 1141px 611px var(--stars), 925px 450px var(--stars), 1781px 1876px var(--stars),
//     1415px 1118px var(--stars), 214px 1766px var(--stars), 1461px 892px var(--stars), 124px 892px var(--stars),
//     1587px 1656px var(--stars), 249px 398px var(--stars), 111px 1118px var(--stars), 1389px 897px var(--stars),
//     1896px 1223px var(--stars), 146px 1494px var(--stars), 1997px 287px var(--stars), 1099px 1963px var(--stars),
//     15px 946px var(--stars), 1057px 1016px var(--stars), 382px 1278px var(--stars), 299px 630px var(--stars),
//     1016px 661px var(--stars), 1373px 249px var(--stars), 1444px 1058px var(--stars), 470px 963px var(--stars),
//     771px 1542px var(--stars), 1582px 1746px var(--stars), 1505px 737px var(--stars), 724px 41px var(--stars),
//     157px 1362px var(--stars), 1560px 157px var(--stars), 1207px 852px var(--stars), 237px 633px var(--stars),
//     1382px 314px var(--stars), 1363px 815px var(--stars), 449px 688px var(--stars), 535px 291px var(--stars),
//     738px 1658px var(--stars), 1611px 828px var(--stars), 80px 285px var(--stars), 775px 389px var(--stars),
//     932px 174px var(--stars), 578px 648px var(--stars), 370px 65px var(--stars), 1483px 1245px var(--stars),
//     1121px 1660px var(--stars), 483px 270px var(--stars), 934px 1837px var(--stars), 1772px 1340px var(--stars),
//     441px 443px var(--stars), 822px 824px var(--stars), 1748px 207px var(--stars), 46px 1549px var(--stars),
//     210px 1132px var(--stars), 509px 818px var(--stars), 18px 1533px var(--stars), 569px 1167px var(--stars),
//     1208px 1905px var(--stars), 836px 1041px var(--stars), 1596px 422px var(--stars), 1011px 586px var(--stars),
//     1485px 995px var(--stars), 46px 1165px var(--stars), 1379px 248px var(--stars), 1932px 1722px var(--stars),
//     1598px 468px var(--stars), 1684px 1041px var(--stars), 185px 779px var(--stars), 931px 202px var(--stars),
//     347px 91px var(--stars), 1636px 780px var(--stars), 1420px 247px var(--stars), 774px 771px var(--stars),
//     1281px 124px var(--stars), 717px 1497px var(--stars), 1489px 1241px var(--stars), 792px 1217px var(--stars),
//     219px 803px var(--stars), 1475px 992px var(--stars), 1335px 1875px var(--stars), 1979px 247px var(--stars),
//     694px 1067px var(--stars), 359px 1592px var(--stars), 1481px 1582px var(--stars), 1970px 1081px var(--stars),
//     568px 1827px var(--stars), 1595px 771px var(--stars), 256px 1623px var(--stars), 356px 1192px var(--stars),
//     713px 1465px var(--stars), 1761px 1453px var(--stars), 39px 20px var(--stars), 298px 868px var(--stars),
//     1315px 1368px var(--stars), 964px 70px var(--stars), 119px 26px var(--stars), 576px 1752px var(--stars),
//     512px 619px var(--stars), 1931px 791px var(--stars), 1374px 232px var(--stars), 1974px 877px var(--stars),
//     143px 1712px var(--stars), 320px 1551px var(--stars), 467px 1562px var(--stars), 1201px 1084px var(--stars),
//     735px 754px var(--stars), 1309px 833px var(--stars), 1454px 1627px var(--stars), 1363px 728px var(--stars),
//     287px 610px var(--stars), 662px 1341px var(--stars), 1844px 1666px var(--stars), 1295px 872px var(--stars),
//     1202px 1500px var(--stars), 1948px 1077px var(--stars), 16px 279px var(--stars), 776px 1810px var(--stars),
//     621px 125px var(--stars), 1119px 1563px var(--stars), 1156px 353px var(--stars), 991px 516px var(--stars),
//     1505px 289px var(--stars), 1171px 1075px var(--stars), 252px 677px var(--stars), 1908px 564px var(--stars),
//     1235px 1309px var(--stars), 948px 1393px var(--stars), 1740px 1851px var(--stars),
//     1770px 1084px var(--stars), 666px 85px var(--stars), 352px 1329px var(--stars), 1362px 1410px var(--stars),
//     407px 1959px var(--stars), 612px 1553px var(--stars), 1930px 1383px var(--stars), 252px 192px var(--stars),
//     82px 9px var(--stars), 1158px 346px var(--stars), 123px 516px var(--stars), 1463px 666px var(--stars),
//     123px 1084px var(--stars), 23px 983px var(--stars), 599px 659px var(--stars), 1428px 1614px var(--stars),
//     811px 704px var(--stars), 412px 525px var(--stars), 191px 343px var(--stars), 955px 1977px var(--stars),
//     1809px 864px var(--stars), 1371px 1037px var(--stars), 722px 1486px var(--stars), 607px 336px var(--stars),
//     1148px 18px var(--stars), 786px 1463px var(--stars), 1200px 1858px var(--stars), 1397px 627px var(--stars),
//     1222px 1743px var(--stars), 1696px 245px var(--stars), 230px 281px var(--stars), 1568px 692px var(--stars),
//     1845px 715px var(--stars), 1842px 635px var(--stars), 195px 724px var(--stars), 1765px 1893px var(--stars),
//     337px 1582px var(--stars), 433px 1149px var(--stars), 1684px 1871px var(--stars), 901px 718px var(--stars),
//     1073px 1289px var(--stars), 819px 890px var(--stars), 1141px 702px var(--stars), 629px 283px var(--stars),
//     772px 1371px var(--stars), 1799px 1444px var(--stars), 185px 1430px var(--stars), 1439px 846px var(--stars),
//     207px 1565px var(--stars), 697px 652px var(--stars), 18px 772px var(--stars), 1409px 997px var(--stars),
//     1590px 713px var(--stars), 1317px 863px var(--stars), 1509px 1503px var(--stars), 1115px 1527px var(--stars),
//     386px 1580px var(--stars), 910px 1225px var(--stars), 105px 1023px var(--stars), 1407px 705px var(--stars),
//     1067px 1198px var(--stars), 11px 938px var(--stars), 1546px 1624px var(--stars), 1072px 1291px var(--stars),
//     1423px 498px var(--stars), 762px 650px var(--stars), 568px 1464px var(--stars), 1557px 1984px var(--stars),
//     738px 14px var(--stars), 564px 1548px var(--stars), 541px 1225px var(--stars), 144px 1319px var(--stars),
//     1463px 1047px var(--stars), 594px 1642px var(--stars), 52px 1791px var(--stars), 1734px 1998px var(--stars),
//     659px 91px var(--stars), 547px 1952px var(--stars), 1290px 720px var(--stars), 458px 782px var(--stars),
//     1555px 1519px var(--stars), 1742px 1756px var(--stars), 710px 1153px var(--stars),
//     1174px 1277px var(--stars), 1750px 672px var(--stars), 1919px 39px var(--stars), 1786px 980px var(--stars),
//     266px 396px var(--stars), 1555px 1103px var(--stars), 483px 966px var(--stars), 983px 1947px var(--stars),
//     1094px 1225px var(--stars), 1212px 350px var(--stars), 1046px 1917px var(--stars),
//     1877px 1027px var(--stars), 87px 1208px var(--stars), 67px 282px var(--stars), 1423px 426px var(--stars),
//     1283px 326px var(--stars), 881px 408px var(--stars), 1849px 506px var(--stars), 610px 816px var(--stars),
//     401px 101px var(--stars), 1630px 1883px var(--stars), 449px 294px var(--stars), 419px 1763px var(--stars),
//     994px 566px var(--stars), 1989px 36px var(--stars), 42px 71px var(--stars), 158px 711px var(--stars),
//     476px 1048px var(--stars), 1474px 1122px var(--stars), 851px 526px var(--stars), 1131px 1360px var(--stars),
//     66px 849px var(--stars), 597px 877px var(--stars), 863px 394px var(--stars), 1417px 1806px var(--stars),
//     1567px 680px var(--stars), 1137px 1778px var(--stars), 512px 1090px var(--stars), 1699px 1362px var(--stars),
//     631px 471px var(--stars), 1058px 746px var(--stars), 1669px 1379px var(--stars), 1703px 501px var(--stars),
//     895px 955px var(--stars), 920px 304px var(--stars), 1762px 259px var(--stars), 1247px 260px var(--stars),
//     523px 1424px var(--stars), 705px 1176px var(--stars), 1123px 189px var(--stars), 7px 1487px var(--stars),
//     481px 618px var(--stars), 593px 1565px var(--stars), 583px 1049px var(--stars), 1673px 937px var(--stars),
//     1973px 1535px var(--stars), 270px 293px var(--stars), 402px 1355px var(--stars), 444px 51px var(--stars),
//     1991px 636px var(--stars), 268px 674px var(--stars), 168px 1749px var(--stars), 1125px 1286px var(--stars),
//     1356px 1068px var(--stars), 364px 1185px var(--stars), 65px 839px var(--stars), 1628px 310px var(--stars),
//     1698px 1743px var(--stars), 970px 1730px var(--stars), 1158px 976px var(--stars), 484px 911px var(--stars),
//     494px 567px var(--stars), 1241px 773px var(--stars), 1066px 1363px var(--stars), 1644px 487px var(--stars),
//     671px 202px var(--stars), 1771px 305px var(--stars), 1136px 635px var(--stars), 1462px 1875px var(--stars),
//     4px 1779px var(--stars), 1411px 1273px var(--stars), 990px 337px var(--stars), 40px 704px var(--stars),
//     1623px 1094px var(--stars), 712px 1999px var(--stars), 152px 515px var(--stars), 1035px 1482px var(--stars),
//     1461px 106px var(--stars), 1759px 149px var(--stars), 410px 1974px var(--stars), 438px 582px var(--stars),
//     1837px 1915px var(--stars), 702px 1213px var(--stars), 1508px 1748px var(--stars), 99px 1634px var(--stars),
//     720px 904px var(--stars), 1795px 1457px var(--stars), 1417px 329px var(--stars), 18px 950px var(--stars),
//     1342px 189px var(--stars), 1965px 802px var(--stars), 952px 1527px var(--stars), 1173px 1436px var(--stars),
//     158px 454px var(--stars), 342px 1502px var(--stars), 1169px 1599px var(--stars), 1978px 2000px var(--stars),
//     1990px 921px var(--stars), 337px 428px var(--stars), 69px 1787px var(--stars), 1155px 950px var(--stars),
//     1787px 1250px var(--stars), 1337px 36px var(--stars), 933px 365px var(--stars), 1869px 551px var(--stars),
//     1305px 1881px var(--stars), 1752px 1580px var(--stars), 1592px 1755px var(--stars),
//     1232px 457px var(--stars), 1585px 1626px var(--stars), 1975px 308px var(--stars), 1280px 1899px var(--stars),
//     1280px 1385px var(--stars), 1770px 1801px var(--stars), 632px 436px var(--stars), 1923px 36px var(--stars),
//     681px 873px var(--stars), 1914px 1395px var(--stars), 596px 1889px var(--stars), 888px 394px var(--stars),
//     1502px 1512px var(--stars), 1494px 882px var(--stars), 1156px 1217px var(--stars), 49px 1928px var(--stars),
//     1843px 292px var(--stars), 1664px 1322px var(--stars), 1857px 275px var(--stars), 332px 631px var(--stars),
//     1028px 938px var(--stars), 6px 1941px var(--stars), 1831px 742px var(--stars), 294px 859px var(--stars),
//     1595px 1514px var(--stars), 1092px 1323px var(--stars), 854px 1047px var(--stars), 1492px 748px var(--stars),
//     1179px 1002px var(--stars), 343px 1541px var(--stars), 1257px 1501px var(--stars),
//     1299px 1067px var(--stars), 1717px 508px var(--stars), 1335px 440px var(--stars), 1386px 482px var(--stars),
//     1094px 1254px var(--stars), 407px 493px var(--stars), 1914px 334px var(--stars), 81px 1922px var(--stars),
//     107px 158px var(--stars), 1125px 544px var(--stars), 1511px 1303px var(--stars), 1885px 1311px var(--stars),
//     1565px 77px var(--stars), 1007px 1220px var(--stars), 1184px 85px var(--stars), 1107px 1117px var(--stars),
//     554px 235px var(--stars), 940px 278px var(--stars), 1676px 79px var(--stars), 1403px 1332px var(--stars),
//     1902px 556px var(--stars), 1591px 1940px var(--stars), 1135px 600px var(--stars), 1015px 1863px var(--stars),
//     1900px 296px var(--stars), 1849px 149px var(--stars), 1025px 719px var(--stars), 1737px 24px var(--stars),
//     1386px 10px var(--stars), 493px 1204px var(--stars), 1193px 566px var(--stars), 1579px 756px var(--stars),
//     58px 1871px var(--stars), 1919px 181px var(--stars), 925px 1792px var(--stars), 1447px 979px var(--stars),
//     1245px 48px var(--stars), 1014px 957px var(--stars), 991px 678px var(--stars), 824px 517px var(--stars),
//     731px 1495px var(--stars), 1277px 208px var(--stars), 789px 949px var(--stars), 531px 876px var(--stars),
//     1524px 1808px var(--stars), 206px 1520px var(--stars), 1434px 1863px var(--stars), 307px 1996px var(--stars),
//     577px 326px var(--stars), 1295px 854px var(--stars), 347px 812px var(--stars), 779px 1164px var(--stars),
//     1116px 733px var(--stars), 848px 1295px var(--stars), 1109px 1041px var(--stars), 1420px 400px var(--stars),
//     155px 507px var(--stars), 147px 242px var(--stars), 275px 1261px var(--stars), 1976px 1553px var(--stars),
//     1050px 1640px var(--stars), 1525px 50px var(--stars), 797px 636px var(--stars), 1668px 268px var(--stars),
//     1223px 951px var(--stars), 1293px 527px var(--stars), 531px 1274px var(--stars), 1996px 527px var(--stars),
//     1885px 652px var(--stars), 95px 1054px var(--stars), 1929px 572px var(--stars), 755px 1700px var(--stars),
//     471px 1239px var(--stars), 445px 1601px var(--stars), 874px 1874px var(--stars), 1132px 977px var(--stars),
//     33px 292px var(--stars), 254px 1868px var(--stars), 824px 706px var(--stars), 1084px 1156px var(--stars),
//     1044px 548px var(--stars), 953px 1286px var(--stars), 1056px 95px var(--stars), 1268px 479px var(--stars),
//     1120px 1579px var(--stars), 989px 1006px var(--stars), 1854px 1398px var(--stars), 407px 765px var(--stars),
//     1873px 1625px var(--stars), 586px 726px var(--stars), 1605px 1829px var(--stars), 381px 843px var(--stars),
//     1419px 776px var(--stars), 1496px 74px var(--stars), 134px 1823px var(--stars), 1026px 954px var(--stars),
//     1932px 1052px var(--stars), 309px 1473px var(--stars), 1702px 347px var(--stars), 766px 1396px var(--stars),
//     716px 69px var(--stars), 46px 1309px var(--stars), 310px 276px var(--stars), 1299px 1183px var(--stars),
//     50px 865px var(--stars), 341px 947px var(--stars), 962px 30px var(--stars), 440px 1886px var(--stars),
//     182px 290px var(--stars), 1892px 642px var(--stars), 1673px 214px var(--stars), 1654px 1864px var(--stars),
//     180px 532px var(--stars), 48px 582px var(--stars), 969px 1458px var(--stars), 1071px 778px var(--stars),
//     1428px 861px var(--stars), 1011px 919px var(--stars), 1310px 1853px var(--stars), 1945px 118px var(--stars),
//     1505px 1470px var(--stars), 339px 1558px var(--stars), 574px 770px var(--stars), 1040px 348px var(--stars),
//     597px 1105px var(--stars), 1448px 1471px var(--stars), 890px 1001px var(--stars), 795px 1112px var(--stars),
//     1409px 747px var(--stars), 1748px 1684px var(--stars), 949px 1533px var(--stars), 335px 1981px var(--stars),
//     552px 729px var(--stars), 341px 653px var(--stars), 1395px 634px var(--stars), 152px 1999px var(--stars),
//     601px 1879px var(--stars), 565px 473px var(--stars), 14px 713px var(--stars), 511px 1293px var(--stars),
//     1077px 941px var(--stars), 1785px 840px var(--stars), 642px 1714px var(--stars), 822px 153px var(--stars),
//     508px 1604px var(--stars), 371px 594px var(--stars), 302px 1166px var(--stars), 1906px 1577px var(--stars),
//     562px 1402px var(--stars), 630px 1328px var(--stars), 306px 1127px var(--stars), 788px 285px var(--stars),
//     1052px 519px var(--stars), 222px 697px var(--stars), 1137px 1287px var(--stars), 1702px 569px var(--stars),
//     1256px 819px var(--stars), 94px 1522px var(--stars), 708px 1277px var(--stars), 84px 1974px var(--stars),
//     1712px 1498px var(--stars), 901px 1419px var(--stars), 346px 1678px var(--stars), 516px 1396px var(--stars),
//     106px 1257px var(--stars), 621px 449px var(--stars), 1757px 361px var(--stars), 756px 693px var(--stars),
//     1175px 66px var(--stars), 1110px 1618px var(--stars), 1497px 1711px var(--stars), 1073px 901px var(--stars),
//     740px 1468px var(--stars), 1088px 233px var(--stars), 1017px 958px var(--stars), 1251px 1744px var(--stars),
//     1588px 233px var(--stars), 1303px 1398px var(--stars), 701px 233px var(--stars), 692px 1611px var(--stars),
//     464px 409px var(--stars), 1921px 614px var(--stars), 32px 1317px var(--stars), 277px 1565px var(--stars),
//     620px 73px var(--stars), 1121px 1605px var(--stars), 431px 1662px var(--stars), 1563px 290px var(--stars),
//     776px 480px var(--stars), 237px 1062px var(--stars), 1027px 783px var(--stars), 423px 610px var(--stars),
//     1089px 1179px var(--stars), 1122px 1031px var(--stars), 1623px 1935px var(--stars),
//     1152px 1843px var(--stars), 1855px 1625px var(--stars), 494px 1164px var(--stars),
//     1003px 1390px var(--stars), 1241px 1605px var(--stars), 454px 9px var(--stars), 310px 1614px var(--stars),
//     1548px 1758px var(--stars), 1415px 1113px var(--stars), 1548px 1849px var(--stars),
//     1517px 1779px var(--stars), 28px 992px var(--stars), 1439px 48px var(--stars), 198px 539px var(--stars),
//     649px 188px var(--stars), 435px 258px var(--stars), 1133px 896px var(--stars), 1674px 705px var(--stars),
//     982px 1393px var(--stars), 934px 1271px var(--stars), 7px 1680px var(--stars), 1161px 491px var(--stars),
//     1577px 390px var(--stars), 1885px 1395px var(--stars), 318px 1933px var(--stars), 285px 591px var(--stars),
//     1228px 305px var(--stars), 523px 1122px var(--stars), 1584px 1015px var(--stars), 1058px 1311px var(--stars),
//     281px 1610px var(--stars), 729px 1401px var(--stars), 436px 1573px var(--stars), 1822px 1563px var(--stars),
//     1463px 1604px var(--stars), 1671px 1223px var(--stars);
//   animation: animStar 50s linear infinite;
// }
// #stars:after {
//   content: " ";
//   position: absolute;
//   top: 2000px;
//   width: 1px;
//   height: 1px;
//   background: transparent;
//   box-shadow: 1111px 1897px var(--stars), 3px 1002px var(--stars), 1334px 620px var(--stars),
//     1826px 1295px var(--stars), 1145px 1216px var(--stars), 146px 628px var(--stars), 1132px 434px var(--stars),
//     1946px 1510px var(--stars), 280px 554px var(--stars), 659px 530px var(--stars), 1211px 79px var(--stars),
//     1043px 441px var(--stars), 177px 847px var(--stars), 1127px 920px var(--stars), 771px 1745px var(--stars),
//     530px 1533px var(--stars), 1628px 462px var(--stars), 1437px 673px var(--stars), 457px 1567px var(--stars),
//     1776px 1136px var(--stars), 1430px 330px var(--stars), 100px 652px var(--stars), 1632px 431px var(--stars),
//     510px 684px var(--stars), 1441px 986px var(--stars), 624px 1837px var(--stars), 1006px 484px var(--stars),
//     1930px 1047px var(--stars), 1561px 57px var(--stars), 1051px 314px var(--stars), 520px 1617px var(--stars),
//     740px 1887px var(--stars), 1897px 1735px var(--stars), 1960px 173px var(--stars), 465px 172px var(--stars),
//     219px 1288px var(--stars), 119px 1571px var(--stars), 1482px 1651px var(--stars), 1870px 374px var(--stars),
//     459px 1778px var(--stars), 1859px 801px var(--stars), 1280px 376px var(--stars), 957px 1121px var(--stars),
//     1665px 1370px var(--stars), 336px 656px var(--stars), 1904px 1157px var(--stars), 1113px 1660px var(--stars),
//     1735px 1710px var(--stars), 920px 907px var(--stars), 919px 625px var(--stars), 1641px 1662px var(--stars),
//     1967px 1427px var(--stars), 66px 245px var(--stars), 536px 472px var(--stars), 1651px 796px var(--stars),
//     1949px 614px var(--stars), 1139px 827px var(--stars), 1013px 1192px var(--stars), 1688px 1438px var(--stars),
//     555px 252px var(--stars), 707px 1071px var(--stars), 428px 221px var(--stars), 13px 1211px var(--stars),
//     1030px 937px var(--stars), 498px 1605px var(--stars), 1542px 61px var(--stars), 1186px 1863px var(--stars),
//     582px 619px var(--stars), 46px 989px var(--stars), 1457px 1744px var(--stars), 537px 3px var(--stars),
//     1360px 1714px var(--stars), 245px 1393px var(--stars), 1128px 1172px var(--stars),
//     1607px 1582px var(--stars), 1166px 679px var(--stars), 725px 1414px var(--stars), 45px 1956px var(--stars),
//     130px 483px var(--stars), 1241px 1045px var(--stars), 726px 1038px var(--stars), 1191px 1697px var(--stars),
//     1706px 1819px var(--stars), 722px 1422px var(--stars), 1438px 1445px var(--stars), 693px 303px var(--stars),
//     1313px 480px var(--stars), 1660px 1988px var(--stars), 107px 1504px var(--stars), 991px 1693px var(--stars),
//     1203px 1241px var(--stars), 580px 69px var(--stars), 1116px 1098px var(--stars), 1445px 706px var(--stars),
//     1656px 460px var(--stars), 862px 141px var(--stars), 66px 1592px var(--stars), 145px 1591px var(--stars),
//     1174px 901px var(--stars), 1824px 1823px var(--stars), 1116px 829px var(--stars), 927px 1490px var(--stars),
//     524px 1078px var(--stars), 1523px 1495px var(--stars), 1277px 1122px var(--stars), 46px 1349px var(--stars),
//     1244px 654px var(--stars), 1824px 1398px var(--stars), 1597px 1389px var(--stars), 896px 965px var(--stars),
//     584px 1319px var(--stars), 1141px 611px var(--stars), 925px 450px var(--stars), 1781px 1876px var(--stars),
//     1415px 1118px var(--stars), 214px 1766px var(--stars), 1461px 892px var(--stars), 124px 892px var(--stars),
//     1587px 1656px var(--stars), 249px 398px var(--stars), 111px 1118px var(--stars), 1389px 897px var(--stars),
//     1896px 1223px var(--stars), 146px 1494px var(--stars), 1997px 287px var(--stars), 1099px 1963px var(--stars),
//     15px 946px var(--stars), 1057px 1016px var(--stars), 382px 1278px var(--stars), 299px 630px var(--stars),
//     1016px 661px var(--stars), 1373px 249px var(--stars), 1444px 1058px var(--stars), 470px 963px var(--stars),
//     771px 1542px var(--stars), 1582px 1746px var(--stars), 1505px 737px var(--stars), 724px 41px var(--stars),
//     157px 1362px var(--stars), 1560px 157px var(--stars), 1207px 852px var(--stars), 237px 633px var(--stars),
//     1382px 314px var(--stars), 1363px 815px var(--stars), 449px 688px var(--stars), 535px 291px var(--stars),
//     738px 1658px var(--stars), 1611px 828px var(--stars), 80px 285px var(--stars), 775px 389px var(--stars),
//     932px 174px var(--stars), 578px 648px var(--stars), 370px 65px var(--stars), 1483px 1245px var(--stars),
//     1121px 1660px var(--stars), 483px 270px var(--stars), 934px 1837px var(--stars), 1772px 1340px var(--stars),
//     441px 443px var(--stars), 822px 824px var(--stars), 1748px 207px var(--stars), 46px 1549px var(--stars),
//     210px 1132px var(--stars), 509px 818px var(--stars), 18px 1533px var(--stars), 569px 1167px var(--stars),
//     1208px 1905px var(--stars), 836px 1041px var(--stars), 1596px 422px var(--stars), 1011px 586px var(--stars),
//     1485px 995px var(--stars), 46px 1165px var(--stars), 1379px 248px var(--stars), 1932px 1722px var(--stars),
//     1598px 468px var(--stars), 1684px 1041px var(--stars), 185px 779px var(--stars), 931px 202px var(--stars),
//     347px 91px var(--stars), 1636px 780px var(--stars), 1420px 247px var(--stars), 774px 771px var(--stars),
//     1281px 124px var(--stars), 717px 1497px var(--stars), 1489px 1241px var(--stars), 792px 1217px var(--stars),
//     219px 803px var(--stars), 1475px 992px var(--stars), 1335px 1875px var(--stars), 1979px 247px var(--stars),
//     694px 1067px var(--stars), 359px 1592px var(--stars), 1481px 1582px var(--stars), 1970px 1081px var(--stars),
//     568px 1827px var(--stars), 1595px 771px var(--stars), 256px 1623px var(--stars), 356px 1192px var(--stars),
//     713px 1465px var(--stars), 1761px 1453px var(--stars), 39px 20px var(--stars), 298px 868px var(--stars),
//     1315px 1368px var(--stars), 964px 70px var(--stars), 119px 26px var(--stars), 576px 1752px var(--stars),
//     512px 619px var(--stars), 1931px 791px var(--stars), 1374px 232px var(--stars), 1974px 877px var(--stars),
//     143px 1712px var(--stars), 320px 1551px var(--stars), 467px 1562px var(--stars), 1201px 1084px var(--stars),
//     735px 754px var(--stars), 1309px 833px var(--stars), 1454px 1627px var(--stars), 1363px 728px var(--stars),
//     287px 610px var(--stars), 662px 1341px var(--stars), 1844px 1666px var(--stars), 1295px 872px var(--stars),
//     1202px 1500px var(--stars), 1948px 1077px var(--stars), 16px 279px var(--stars), 776px 1810px var(--stars),
//     621px 125px var(--stars), 1119px 1563px var(--stars), 1156px 353px var(--stars), 991px 516px var(--stars),
//     1505px 289px var(--stars), 1171px 1075px var(--stars), 252px 677px var(--stars), 1908px 564px var(--stars),
//     1235px 1309px var(--stars), 948px 1393px var(--stars), 1740px 1851px var(--stars),
//     1770px 1084px var(--stars), 666px 85px var(--stars), 352px 1329px var(--stars), 1362px 1410px var(--stars),
//     407px 1959px var(--stars), 612px 1553px var(--stars), 1930px 1383px var(--stars), 252px 192px var(--stars),
//     82px 9px var(--stars), 1158px 346px var(--stars), 123px 516px var(--stars), 1463px 666px var(--stars),
//     123px 1084px var(--stars), 23px 983px var(--stars), 599px 659px var(--stars), 1428px 1614px var(--stars),
//     811px 704px var(--stars), 412px 525px var(--stars), 191px 343px var(--stars), 955px 1977px var(--stars),
//     1809px 864px var(--stars), 1371px 1037px var(--stars), 722px 1486px var(--stars), 607px 336px var(--stars),
//     1148px 18px var(--stars), 786px 1463px var(--stars), 1200px 1858px var(--stars), 1397px 627px var(--stars),
//     1222px 1743px var(--stars), 1696px 245px var(--stars), 230px 281px var(--stars), 1568px 692px var(--stars),
//     1845px 715px var(--stars), 1842px 635px var(--stars), 195px 724px var(--stars), 1765px 1893px var(--stars),
//     337px 1582px var(--stars), 433px 1149px var(--stars), 1684px 1871px var(--stars), 901px 718px var(--stars),
//     1073px 1289px var(--stars), 819px 890px var(--stars), 1141px 702px var(--stars), 629px 283px var(--stars),
//     772px 1371px var(--stars), 1799px 1444px var(--stars), 185px 1430px var(--stars), 1439px 846px var(--stars),
//     207px 1565px var(--stars), 697px 652px var(--stars), 18px 772px var(--stars), 1409px 997px var(--stars),
//     1590px 713px var(--stars), 1317px 863px var(--stars), 1509px 1503px var(--stars), 1115px 1527px var(--stars),
//     386px 1580px var(--stars), 910px 1225px var(--stars), 105px 1023px var(--stars), 1407px 705px var(--stars),
//     1067px 1198px var(--stars), 11px 938px var(--stars), 1546px 1624px var(--stars), 1072px 1291px var(--stars),
//     1423px 498px var(--stars), 762px 650px var(--stars), 568px 1464px var(--stars), 1557px 1984px var(--stars),
//     738px 14px var(--stars), 564px 1548px var(--stars), 541px 1225px var(--stars), 144px 1319px var(--stars),
//     1463px 1047px var(--stars), 594px 1642px var(--stars), 52px 1791px var(--stars), 1734px 1998px var(--stars),
//     659px 91px var(--stars), 547px 1952px var(--stars), 1290px 720px var(--stars), 458px 782px var(--stars),
//     1555px 1519px var(--stars), 1742px 1756px var(--stars), 710px 1153px var(--stars),
//     1174px 1277px var(--stars), 1750px 672px var(--stars), 1919px 39px var(--stars), 1786px 980px var(--stars),
//     266px 396px var(--stars), 1555px 1103px var(--stars), 483px 966px var(--stars), 983px 1947px var(--stars),
//     1094px 1225px var(--stars), 1212px 350px var(--stars), 1046px 1917px var(--stars),
//     1877px 1027px var(--stars), 87px 1208px var(--stars), 67px 282px var(--stars), 1423px 426px var(--stars),
//     1283px 326px var(--stars), 881px 408px var(--stars), 1849px 506px var(--stars), 610px 816px var(--stars),
//     401px 101px var(--stars), 1630px 1883px var(--stars), 449px 294px var(--stars), 419px 1763px var(--stars),
//     994px 566px var(--stars), 1989px 36px var(--stars), 42px 71px var(--stars), 158px 711px var(--stars),
//     476px 1048px var(--stars), 1474px 1122px var(--stars), 851px 526px var(--stars), 1131px 1360px var(--stars),
//     66px 849px var(--stars), 597px 877px var(--stars), 863px 394px var(--stars), 1417px 1806px var(--stars),
//     1567px 680px var(--stars), 1137px 1778px var(--stars), 512px 1090px var(--stars), 1699px 1362px var(--stars),
//     631px 471px var(--stars), 1058px 746px var(--stars), 1669px 1379px var(--stars), 1703px 501px var(--stars),
//     895px 955px var(--stars), 920px 304px var(--stars), 1762px 259px var(--stars), 1247px 260px var(--stars),
//     523px 1424px var(--stars), 705px 1176px var(--stars), 1123px 189px var(--stars), 7px 1487px var(--stars),
//     481px 618px var(--stars), 593px 1565px var(--stars), 583px 1049px var(--stars), 1673px 937px var(--stars),
//     1973px 1535px var(--stars), 270px 293px var(--stars), 402px 1355px var(--stars), 444px 51px var(--stars),
//     1991px 636px var(--stars), 268px 674px var(--stars), 168px 1749px var(--stars), 1125px 1286px var(--stars),
//     1356px 1068px var(--stars), 364px 1185px var(--stars), 65px 839px var(--stars), 1628px 310px var(--stars),
//     1698px 1743px var(--stars), 970px 1730px var(--stars), 1158px 976px var(--stars), 484px 911px var(--stars),
//     494px 567px var(--stars), 1241px 773px var(--stars), 1066px 1363px var(--stars), 1644px 487px var(--stars),
//     671px 202px var(--stars), 1771px 305px var(--stars), 1136px 635px var(--stars), 1462px 1875px var(--stars),
//     4px 1779px var(--stars), 1411px 1273px var(--stars), 990px 337px var(--stars), 40px 704px var(--stars),
//     1623px 1094px var(--stars), 712px 1999px var(--stars), 152px 515px var(--stars), 1035px 1482px var(--stars),
//     1461px 106px var(--stars), 1759px 149px var(--stars), 410px 1974px var(--stars), 438px 582px var(--stars),
//     1837px 1915px var(--stars), 702px 1213px var(--stars), 1508px 1748px var(--stars), 99px 1634px var(--stars),
//     720px 904px var(--stars), 1795px 1457px var(--stars), 1417px 329px var(--stars), 18px 950px var(--stars),
//     1342px 189px var(--stars), 1965px 802px var(--stars), 952px 1527px var(--stars), 1173px 1436px var(--stars),
//     158px 454px var(--stars), 342px 1502px var(--stars), 1169px 1599px var(--stars), 1978px 2000px var(--stars),
//     1990px 921px var(--stars), 337px 428px var(--stars), 69px 1787px var(--stars), 1155px 950px var(--stars),
//     1787px 1250px var(--stars), 1337px 36px var(--stars), 933px 365px var(--stars), 1869px 551px var(--stars),
//     1305px 1881px var(--stars), 1752px 1580px var(--stars), 1592px 1755px var(--stars),
//     1232px 457px var(--stars), 1585px 1626px var(--stars), 1975px 308px var(--stars), 1280px 1899px var(--stars),
//     1280px 1385px var(--stars), 1770px 1801px var(--stars), 632px 436px var(--stars), 1923px 36px var(--stars),
//     681px 873px var(--stars), 1914px 1395px var(--stars), 596px 1889px var(--stars), 888px 394px var(--stars),
//     1502px 1512px var(--stars), 1494px 882px var(--stars), 1156px 1217px var(--stars), 49px 1928px var(--stars),
//     1843px 292px var(--stars), 1664px 1322px var(--stars), 1857px 275px var(--stars), 332px 631px var(--stars),
//     1028px 938px var(--stars), 6px 1941px var(--stars), 1831px 742px var(--stars), 294px 859px var(--stars),
//     1595px 1514px var(--stars), 1092px 1323px var(--stars), 854px 1047px var(--stars), 1492px 748px var(--stars),
//     1179px 1002px var(--stars), 343px 1541px var(--stars), 1257px 1501px var(--stars),
//     1299px 1067px var(--stars), 1717px 508px var(--stars), 1335px 440px var(--stars), 1386px 482px var(--stars),
//     1094px 1254px var(--stars), 407px 493px var(--stars), 1914px 334px var(--stars), 81px 1922px var(--stars),
//     107px 158px var(--stars), 1125px 544px var(--stars), 1511px 1303px var(--stars), 1885px 1311px var(--stars),
//     1565px 77px var(--stars), 1007px 1220px var(--stars), 1184px 85px var(--stars), 1107px 1117px var(--stars),
//     554px 235px var(--stars), 940px 278px var(--stars), 1676px 79px var(--stars), 1403px 1332px var(--stars),
//     1902px 556px var(--stars), 1591px 1940px var(--stars), 1135px 600px var(--stars), 1015px 1863px var(--stars),
//     1900px 296px var(--stars), 1849px 149px var(--stars), 1025px 719px var(--stars), 1737px 24px var(--stars),
//     1386px 10px var(--stars), 493px 1204px var(--stars), 1193px 566px var(--stars), 1579px 756px var(--stars),
//     58px 1871px var(--stars), 1919px 181px var(--stars), 925px 1792px var(--stars), 1447px 979px var(--stars),
//     1245px 48px var(--stars), 1014px 957px var(--stars), 991px 678px var(--stars), 824px 517px var(--stars),
//     731px 1495px var(--stars), 1277px 208px var(--stars), 789px 949px var(--stars), 531px 876px var(--stars),
//     1524px 1808px var(--stars), 206px 1520px var(--stars), 1434px 1863px var(--stars), 307px 1996px var(--stars),
//     577px 326px var(--stars), 1295px 854px var(--stars), 347px 812px var(--stars), 779px 1164px var(--stars),
//     1116px 733px var(--stars), 848px 1295px var(--stars), 1109px 1041px var(--stars), 1420px 400px var(--stars),
//     155px 507px var(--stars), 147px 242px var(--stars), 275px 1261px var(--stars), 1976px 1553px var(--stars),
//     1050px 1640px var(--stars), 1525px 50px var(--stars), 797px 636px var(--stars), 1668px 268px var(--stars),
//     1223px 951px var(--stars), 1293px 527px var(--stars), 531px 1274px var(--stars), 1996px 527px var(--stars),
//     1885px 652px var(--stars), 95px 1054px var(--stars), 1929px 572px var(--stars), 755px 1700px var(--stars),
//     471px 1239px var(--stars), 445px 1601px var(--stars), 874px 1874px var(--stars), 1132px 977px var(--stars),
//     33px 292px var(--stars), 254px 1868px var(--stars), 824px 706px var(--stars), 1084px 1156px var(--stars),
//     1044px 548px var(--stars), 953px 1286px var(--stars), 1056px 95px var(--stars), 1268px 479px var(--stars),
//     1120px 1579px var(--stars), 989px 1006px var(--stars), 1854px 1398px var(--stars), 407px 765px var(--stars),
//     1873px 1625px var(--stars), 586px 726px var(--stars), 1605px 1829px var(--stars), 381px 843px var(--stars),
//     1419px 776px var(--stars), 1496px 74px var(--stars), 134px 1823px var(--stars), 1026px 954px var(--stars),
//     1932px 1052px var(--stars), 309px 1473px var(--stars), 1702px 347px var(--stars), 766px 1396px var(--stars),
//     716px 69px var(--stars), 46px 1309px var(--stars), 310px 276px var(--stars), 1299px 1183px var(--stars),
//     50px 865px var(--stars), 341px 947px var(--stars), 962px 30px var(--stars), 440px 1886px var(--stars),
//     182px 290px var(--stars), 1892px 642px var(--stars), 1673px 214px var(--stars), 1654px 1864px var(--stars),
//     180px 532px var(--stars), 48px 582px var(--stars), 969px 1458px var(--stars), 1071px 778px var(--stars),
//     1428px 861px var(--stars), 1011px 919px var(--stars), 1310px 1853px var(--stars), 1945px 118px var(--stars),
//     1505px 1470px var(--stars), 339px 1558px var(--stars), 574px 770px var(--stars), 1040px 348px var(--stars),
//     597px 1105px var(--stars), 1448px 1471px var(--stars), 890px 1001px var(--stars), 795px 1112px var(--stars),
//     1409px 747px var(--stars), 1748px 1684px var(--stars), 949px 1533px var(--stars), 335px 1981px var(--stars),
//     552px 729px var(--stars), 341px 653px var(--stars), 1395px 634px var(--stars), 152px 1999px var(--stars),
//     601px 1879px var(--stars), 565px 473px var(--stars), 14px 713px var(--stars), 511px 1293px var(--stars),
//     1077px 941px var(--stars), 1785px 840px var(--stars), 642px 1714px var(--stars), 822px 153px var(--stars),
//     508px 1604px var(--stars), 371px 594px var(--stars), 302px 1166px var(--stars), 1906px 1577px var(--stars),
//     562px 1402px var(--stars), 630px 1328px var(--stars), 306px 1127px var(--stars), 788px 285px var(--stars),
//     1052px 519px var(--stars), 222px 697px var(--stars), 1137px 1287px var(--stars), 1702px 569px var(--stars),
//     1256px 819px var(--stars), 94px 1522px var(--stars), 708px 1277px var(--stars), 84px 1974px var(--stars),
//     1712px 1498px var(--stars), 901px 1419px var(--stars), 346px 1678px var(--stars), 516px 1396px var(--stars),
//     106px 1257px var(--stars), 621px 449px var(--stars), 1757px 361px var(--stars), 756px 693px var(--stars),
//     1175px 66px var(--stars), 1110px 1618px var(--stars), 1497px 1711px var(--stars), 1073px 901px var(--stars),
//     740px 1468px var(--stars), 1088px 233px var(--stars), 1017px 958px var(--stars), 1251px 1744px var(--stars),
//     1588px 233px var(--stars), 1303px 1398px var(--stars), 701px 233px var(--stars), 692px 1611px var(--stars),
//     464px 409px var(--stars), 1921px 614px var(--stars), 32px 1317px var(--stars), 277px 1565px var(--stars),
//     620px 73px var(--stars), 1121px 1605px var(--stars), 431px 1662px var(--stars), 1563px 290px var(--stars),
//     776px 480px var(--stars), 237px 1062px var(--stars), 1027px 783px var(--stars), 423px 610px var(--stars),
//     1089px 1179px var(--stars), 1122px 1031px var(--stars), 1623px 1935px var(--stars),
//     1152px 1843px var(--stars), 1855px 1625px var(--stars), 494px 1164px var(--stars),
//     1003px 1390px var(--stars), 1241px 1605px var(--stars), 454px 9px var(--stars), 310px 1614px var(--stars),
//     1548px 1758px var(--stars), 1415px 1113px var(--stars), 1548px 1849px var(--stars),
//     1517px 1779px var(--stars), 28px 992px var(--stars), 1439px 48px var(--stars), 198px 539px var(--stars),
//     649px 188px var(--stars), 435px 258px var(--stars), 1133px 896px var(--stars), 1674px 705px var(--stars),
//     982px 1393px var(--stars), 934px 1271px var(--stars), 7px 1680px var(--stars), 1161px 491px var(--stars),
//     1577px 390px var(--stars), 1885px 1395px var(--stars), 318px 1933px var(--stars), 285px 591px var(--stars),
//     1228px 305px var(--stars), 523px 1122px var(--stars), 1584px 1015px var(--stars), 1058px 1311px var(--stars),
//     281px 1610px var(--stars), 729px 1401px var(--stars), 436px 1573px var(--stars), 1822px 1563px var(--stars),
//     1463px 1604px var(--stars), 1671px 1223px var(--stars);
// }

// #stars2 {
//   width: 2px;
//   height: 2px;
//   background: transparent;
//   box-shadow: 610px 1355px var(--stars), 556px 441px var(--stars), 163px 296px var(--stars),
//     1417px 1165px var(--stars), 1420px 1522px var(--stars), 970px 1223px var(--stars), 702px 216px var(--stars),
//     779px 273px var(--stars), 993px 674px var(--stars), 997px 1567px var(--stars), 1697px 1045px var(--stars),
//     1101px 1059px var(--stars), 1673px 1350px var(--stars), 391px 1431px var(--stars), 773px 1301px var(--stars),
//     1403px 1166px var(--stars), 824px 1647px var(--stars), 290px 716px var(--stars), 278px 1181px var(--stars),
//     728px 110px var(--stars), 1677px 377px var(--stars), 848px 233px var(--stars), 1444px 1624px var(--stars),
//     1766px 1658px var(--stars), 1197px 1107px var(--stars), 432px 1982px var(--stars), 58px 1216px var(--stars),
//     412px 443px var(--stars), 1714px 1182px var(--stars), 245px 1381px var(--stars), 311px 65px var(--stars),
//     1362px 1679px var(--stars), 297px 341px var(--stars), 45px 1283px var(--stars), 1601px 1827px var(--stars),
//     1741px 665px var(--stars), 235px 79px var(--stars), 1405px 464px var(--stars), 809px 1944px var(--stars),
//     756px 142px var(--stars), 1095px 1211px var(--stars), 1752px 1602px var(--stars), 511px 277px var(--stars),
//     1050px 1283px var(--stars), 690px 1699px var(--stars), 1491px 1806px var(--stars), 629px 1999px var(--stars),
//     527px 128px var(--stars), 23px 716px var(--stars), 1367px 741px var(--stars), 1533px 672px var(--stars),
//     1339px 1788px var(--stars), 1207px 316px var(--stars), 1727px 76px var(--stars), 31px 1084px var(--stars),
//     1783px 1635px var(--stars), 417px 1671px var(--stars), 131px 89px var(--stars), 973px 363px var(--stars),
//     480px 719px var(--stars), 284px 1293px var(--stars), 1576px 320px var(--stars), 323px 1997px var(--stars),
//     921px 721px var(--stars), 1220px 1060px var(--stars), 1347px 889px var(--stars), 168px 798px var(--stars),
//     643px 1876px var(--stars), 648px 9px var(--stars), 1954px 1008px var(--stars), 76px 878px var(--stars),
//     990px 1785px var(--stars), 710px 475px var(--stars), 813px 325px var(--stars), 1843px 488px var(--stars),
//     568px 471px var(--stars), 403px 1599px var(--stars), 1792px 792px var(--stars), 1849px 1795px var(--stars),
//     1039px 990px var(--stars), 1259px 394px var(--stars), 835px 755px var(--stars), 1047px 1476px var(--stars),
//     862px 344px var(--stars), 597px 734px var(--stars), 1266px 784px var(--stars), 947px 1912px var(--stars),
//     211px 1827px var(--stars), 1683px 562px var(--stars), 1224px 1458px var(--stars), 1429px 1360px var(--stars),
//     470px 1039px var(--stars), 1273px 1184px var(--stars), 575px 368px var(--stars), 457px 1195px var(--stars),
//     1313px 923px var(--stars), 148px 1426px var(--stars), 737px 1222px var(--stars), 1967px 42px var(--stars),
//     1875px 1814px var(--stars), 313px 599px var(--stars), 212px 1164px var(--stars), 1944px 245px var(--stars),
//     1069px 1488px var(--stars), 166px 1123px var(--stars), 533px 1804px var(--stars), 1443px 1776px var(--stars),
//     1481px 1483px var(--stars), 1107px 1978px var(--stars), 235px 612px var(--stars), 151px 1259px var(--stars),
//     584px 1239px var(--stars), 1376px 1954px var(--stars), 1460px 1632px var(--stars),
//     1406px 1588px var(--stars), 144px 1756px var(--stars), 887px 1798px var(--stars), 325px 970px var(--stars),
//     1047px 1228px var(--stars), 1458px 83px var(--stars), 1292px 375px var(--stars), 239px 472px var(--stars),
//     1277px 1548px var(--stars), 100px 127px var(--stars), 1685px 16px var(--stars), 1267px 1564px var(--stars),
//     1527px 318px var(--stars), 924px 1625px var(--stars), 582px 762px var(--stars), 1202px 1342px var(--stars),
//     518px 1438px var(--stars), 1925px 239px var(--stars), 1514px 481px var(--stars), 1279px 244px var(--stars),
//     239px 270px var(--stars), 1375px 315px var(--stars), 78px 739px var(--stars), 488px 1837px var(--stars),
//     1287px 1126px var(--stars), 504px 772px var(--stars), 1804px 731px var(--stars), 1414px 893px var(--stars),
//     103px 1877px var(--stars), 945px 1556px var(--stars), 509px 1142px var(--stars), 1638px 1218px var(--stars),
//     9px 1860px var(--stars), 1413px 399px var(--stars), 1432px 88px var(--stars), 1226px 1678px var(--stars),
//     1061px 44px var(--stars), 1023px 100px var(--stars), 1974px 1280px var(--stars), 1434px 40px var(--stars),
//     20px 1450px var(--stars), 151px 1818px var(--stars), 238px 906px var(--stars), 451px 1550px var(--stars),
//     1164px 1683px var(--stars), 604px 693px var(--stars), 816px 1800px var(--stars), 400px 1792px var(--stars),
//     1527px 1969px var(--stars), 1507px 1026px var(--stars), 1947px 1584px var(--stars),
//     923px 1289px var(--stars), 188px 1000px var(--stars), 764px 1961px var(--stars), 413px 506px var(--stars),
//     610px 997px var(--stars), 12px 745px var(--stars), 828px 613px var(--stars), 546px 1239px var(--stars),
//     1132px 1855px var(--stars), 492px 1022px var(--stars), 969px 650px var(--stars), 584px 437px var(--stars),
//     951px 1519px var(--stars), 1325px 777px var(--stars), 1226px 1031px var(--stars), 1930px 1186px var(--stars),
//     831px 1101px var(--stars), 857px 169px var(--stars), 222px 1202px var(--stars), 776px 604px var(--stars),
//     1166px 1577px var(--stars), 528px 1780px var(--stars), 855px 1771px var(--stars), 637px 385px var(--stars),
//     1106px 802px var(--stars), 1207px 162px var(--stars), 793px 1448px var(--stars), 937px 1976px var(--stars),
//     19px 632px var(--stars), 450px 1201px var(--stars), 678px 1253px var(--stars), 1894px 1050px var(--stars),
//     282px 1449px var(--stars), 41px 1065px var(--stars), 1081px 1658px var(--stars);
//   animation: animStar 100s linear infinite;
// }
// #stars2:after {
//   content: " ";
//   position: absolute;
//   top: 2000px;
//   width: 2px;
//   height: 2px;
//   background: transparent;
//   box-shadow: 610px 1355px var(--stars), 556px 441px var(--stars), 163px 296px var(--stars),
//     1417px 1165px var(--stars), 1420px 1522px var(--stars), 970px 1223px var(--stars), 702px 216px var(--stars),
//     779px 273px var(--stars), 993px 674px var(--stars), 997px 1567px var(--stars), 1697px 1045px var(--stars),
//     1101px 1059px var(--stars), 1673px 1350px var(--stars), 391px 1431px var(--stars), 773px 1301px var(--stars),
//     1403px 1166px var(--stars), 824px 1647px var(--stars), 290px 716px var(--stars), 278px 1181px var(--stars),
//     728px 110px var(--stars), 1677px 377px var(--stars), 848px 233px var(--stars), 1444px 1624px var(--stars),
//     1766px 1658px var(--stars), 1197px 1107px var(--stars), 432px 1982px var(--stars), 58px 1216px var(--stars),
//     412px 443px var(--stars), 1714px 1182px var(--stars), 245px 1381px var(--stars), 311px 65px var(--stars),
//     1362px 1679px var(--stars), 297px 341px var(--stars), 45px 1283px var(--stars), 1601px 1827px var(--stars),
//     1741px 665px var(--stars), 235px 79px var(--stars), 1405px 464px var(--stars), 809px 1944px var(--stars),
//     756px 142px var(--stars), 1095px 1211px var(--stars), 1752px 1602px var(--stars), 511px 277px var(--stars),
//     1050px 1283px var(--stars), 690px 1699px var(--stars), 1491px 1806px var(--stars), 629px 1999px var(--stars),
//     527px 128px var(--stars), 23px 716px var(--stars), 1367px 741px var(--stars), 1533px 672px var(--stars),
//     1339px 1788px var(--stars), 1207px 316px var(--stars), 1727px 76px var(--stars), 31px 1084px var(--stars),
//     1783px 1635px var(--stars), 417px 1671px var(--stars), 131px 89px var(--stars), 973px 363px var(--stars),
//     480px 719px var(--stars), 284px 1293px var(--stars), 1576px 320px var(--stars), 323px 1997px var(--stars),
//     921px 721px var(--stars), 1220px 1060px var(--stars), 1347px 889px var(--stars), 168px 798px var(--stars),
//     643px 1876px var(--stars), 648px 9px var(--stars), 1954px 1008px var(--stars), 76px 878px var(--stars),
//     990px 1785px var(--stars), 710px 475px var(--stars), 813px 325px var(--stars), 1843px 488px var(--stars),
//     568px 471px var(--stars), 403px 1599px var(--stars), 1792px 792px var(--stars), 1849px 1795px var(--stars),
//     1039px 990px var(--stars), 1259px 394px var(--stars), 835px 755px var(--stars), 1047px 1476px var(--stars),
//     862px 344px var(--stars), 597px 734px var(--stars), 1266px 784px var(--stars), 947px 1912px var(--stars),
//     211px 1827px var(--stars), 1683px 562px var(--stars), 1224px 1458px var(--stars), 1429px 1360px var(--stars),
//     470px 1039px var(--stars), 1273px 1184px var(--stars), 575px 368px var(--stars), 457px 1195px var(--stars),
//     1313px 923px var(--stars), 148px 1426px var(--stars), 737px 1222px var(--stars), 1967px 42px var(--stars),
//     1875px 1814px var(--stars), 313px 599px var(--stars), 212px 1164px var(--stars), 1944px 245px var(--stars),
//     1069px 1488px var(--stars), 166px 1123px var(--stars), 533px 1804px var(--stars), 1443px 1776px var(--stars),
//     1481px 1483px var(--stars), 1107px 1978px var(--stars), 235px 612px var(--stars), 151px 1259px var(--stars),
//     584px 1239px var(--stars), 1376px 1954px var(--stars), 1460px 1632px var(--stars),
//     1406px 1588px var(--stars), 144px 1756px var(--stars), 887px 1798px var(--stars), 325px 970px var(--stars),
//     1047px 1228px var(--stars), 1458px 83px var(--stars), 1292px 375px var(--stars), 239px 472px var(--stars),
//     1277px 1548px var(--stars), 100px 127px var(--stars), 1685px 16px var(--stars), 1267px 1564px var(--stars),
//     1527px 318px var(--stars), 924px 1625px var(--stars), 582px 762px var(--stars), 1202px 1342px var(--stars),
//     518px 1438px var(--stars), 1925px 239px var(--stars), 1514px 481px var(--stars), 1279px 244px var(--stars),
//     239px 270px var(--stars), 1375px 315px var(--stars), 78px 739px var(--stars), 488px 1837px var(--stars),
//     1287px 1126px var(--stars), 504px 772px var(--stars), 1804px 731px var(--stars), 1414px 893px var(--stars),
//     103px 1877px var(--stars), 945px 1556px var(--stars), 509px 1142px var(--stars), 1638px 1218px var(--stars),
//     9px 1860px var(--stars), 1413px 399px var(--stars), 1432px 88px var(--stars), 1226px 1678px var(--stars),
//     1061px 44px var(--stars), 1023px 100px var(--stars), 1974px 1280px var(--stars), 1434px 40px var(--stars),
//     20px 1450px var(--stars), 151px 1818px var(--stars), 238px 906px var(--stars), 451px 1550px var(--stars),
//     1164px 1683px var(--stars), 604px 693px var(--stars), 816px 1800px var(--stars), 400px 1792px var(--stars),
//     1527px 1969px var(--stars), 1507px 1026px var(--stars), 1947px 1584px var(--stars),
//     923px 1289px var(--stars), 188px 1000px var(--stars), 764px 1961px var(--stars), 413px 506px var(--stars),
//     610px 997px var(--stars), 12px 745px var(--stars), 828px 613px var(--stars), 546px 1239px var(--stars),
//     1132px 1855px var(--stars), 492px 1022px var(--stars), 969px 650px var(--stars), 584px 437px var(--stars),
//     951px 1519px var(--stars), 1325px 777px var(--stars), 1226px 1031px var(--stars), 1930px 1186px var(--stars),
//     831px 1101px var(--stars), 857px 169px var(--stars), 222px 1202px var(--stars), 776px 604px var(--stars),
//     1166px 1577px var(--stars), 528px 1780px var(--stars), 855px 1771px var(--stars), 637px 385px var(--stars),
//     1106px 802px var(--stars), 1207px 162px var(--stars), 793px 1448px var(--stars), 937px 1976px var(--stars),
//     19px 632px var(--stars), 450px 1201px var(--stars), 678px 1253px var(--stars), 1894px 1050px var(--stars),
//     282px 1449px var(--stars), 41px 1065px var(--stars), 1081px 1658px var(--stars);
// }

// #stars3 {
//   width: 3px;
//   height: 3px;
//   background: transparent;
//   box-shadow: 1736px 826px var(--stars), 1441px 1100px var(--stars), 627px 640px var(--stars),
//     875px 64px var(--stars), 1108px 1875px var(--stars), 1877px 1704px var(--stars), 473px 404px var(--stars),
//     537px 559px var(--stars), 1910px 301px var(--stars), 1652px 1954px var(--stars), 153px 48px var(--stars),
//     545px 583px var(--stars), 931px 1722px var(--stars), 1195px 1739px var(--stars), 216px 1912px var(--stars),
//     448px 596px var(--stars), 431px 1318px var(--stars), 204px 602px var(--stars), 839px 1805px var(--stars),
//     20px 1442px var(--stars), 505px 85px var(--stars), 1544px 926px var(--stars), 121px 1357px var(--stars),
//     1350px 899px var(--stars), 894px 1746px var(--stars), 557px 1082px var(--stars), 594px 1876px var(--stars),
//     1161px 891px var(--stars), 1544px 173px var(--stars), 1143px 114px var(--stars), 1752px 27px var(--stars),
//     972px 359px var(--stars), 590px 1094px var(--stars), 653px 1895px var(--stars), 1045px 419px var(--stars),
//     361px 338px var(--stars), 1928px 1249px var(--stars), 995px 638px var(--stars), 1031px 190px var(--stars),
//     1026px 1412px var(--stars), 483px 1813px var(--stars), 534px 1085px var(--stars), 66px 500px var(--stars),
//     1846px 1870px var(--stars), 1528px 814px var(--stars), 1345px 935px var(--stars), 1132px 302px var(--stars),
//     1102px 868px var(--stars), 265px 88px var(--stars), 232px 911px var(--stars), 752px 1960px var(--stars),
//     450px 1510px var(--stars), 1979px 964px var(--stars), 887px 1579px var(--stars), 1758px 712px var(--stars),
//     1352px 1061px var(--stars), 1920px 515px var(--stars), 253px 1746px var(--stars), 245px 747px var(--stars),
//     154px 1507px var(--stars), 1136px 211px var(--stars), 1953px 1316px var(--stars), 188px 1508px var(--stars),
//     1536px 1395px var(--stars), 405px 1133px var(--stars), 430px 1350px var(--stars), 361px 1220px var(--stars),
//     97px 1538px var(--stars), 627px 1304px var(--stars), 1009px 399px var(--stars), 500px 633px var(--stars),
//     1329px 1433px var(--stars), 577px 1076px var(--stars), 709px 1696px var(--stars), 231px 879px var(--stars),
//     294px 1830px var(--stars), 1733px 1744px var(--stars), 1478px 865px var(--stars), 158px 44px var(--stars),
//     1447px 1724px var(--stars), 292px 797px var(--stars), 146px 1573px var(--stars), 679px 422px var(--stars),
//     770px 1261px var(--stars), 215px 628px var(--stars), 973px 860px var(--stars), 1825px 1163px var(--stars),
//     619px 779px var(--stars), 1193px 1162px var(--stars), 1654px 583px var(--stars), 753px 1216px var(--stars),
//     817px 1774px var(--stars), 1765px 794px var(--stars), 1474px 1274px var(--stars), 819px 802px var(--stars),
//     740px 1076px var(--stars), 507px 1827px var(--stars), 1505px 377px var(--stars), 1142px 404px var(--stars),
//     420px 727px var(--stars);
//   animation: animStar 150s linear infinite;
// }
// #stars3:after {
//   content: " ";
//   position: absolute;
//   top: 2000px;
//   width: 3px;
//   height: 3px;
//   background: transparent;
//   box-shadow: 1736px 826px var(--stars), 1441px 1100px var(--stars), 627px 640px var(--stars),
//     875px 64px var(--stars), 1108px 1875px var(--stars), 1877px 1704px var(--stars), 473px 404px var(--stars),
//     537px 559px var(--stars), 1910px 301px var(--stars), 1652px 1954px var(--stars), 153px 48px var(--stars),
//     545px 583px var(--stars), 931px 1722px var(--stars), 1195px 1739px var(--stars), 216px 1912px var(--stars),
//     448px 596px var(--stars), 431px 1318px var(--stars), 204px 602px var(--stars), 839px 1805px var(--stars),
//     20px 1442px var(--stars), 505px 85px var(--stars), 1544px 926px var(--stars), 121px 1357px var(--stars),
//     1350px 899px var(--stars), 894px 1746px var(--stars), 557px 1082px var(--stars), 594px 1876px var(--stars),
//     1161px 891px var(--stars), 1544px 173px var(--stars), 1143px 114px var(--stars), 1752px 27px var(--stars),
//     972px 359px var(--stars), 590px 1094px var(--stars), 653px 1895px var(--stars), 1045px 419px var(--stars),
//     361px 338px var(--stars), 1928px 1249px var(--stars), 995px 638px var(--stars), 1031px 190px var(--stars),
//     1026px 1412px var(--stars), 483px 1813px var(--stars), 534px 1085px var(--stars), 66px 500px var(--stars),
//     1846px 1870px var(--stars), 1528px 814px var(--stars), 1345px 935px var(--stars), 1132px 302px var(--stars),
//     1102px 868px var(--stars), 265px 88px var(--stars), 232px 911px var(--stars), 752px 1960px var(--stars),
//     450px 1510px var(--stars), 1979px 964px var(--stars), 887px 1579px var(--stars), 1758px 712px var(--stars),
//     1352px 1061px var(--stars), 1920px 515px var(--stars), 253px 1746px var(--stars), 245px 747px var(--stars),
//     154px 1507px var(--stars), 1136px 211px var(--stars), 1953px 1316px var(--stars), 188px 1508px var(--stars),
//     1536px 1395px var(--stars), 405px 1133px var(--stars), 430px 1350px var(--stars), 361px 1220px var(--stars),
//     97px 1538px var(--stars), 627px 1304px var(--stars), 1009px 399px var(--stars), 500px 633px var(--stars),
//     1329px 1433px var(--stars), 577px 1076px var(--stars), 709px 1696px var(--stars), 231px 879px var(--stars),
//     294px 1830px var(--stars), 1733px 1744px var(--stars), 1478px 865px var(--stars), 158px 44px var(--stars),
//     1447px 1724px var(--stars), 292px 797px var(--stars), 146px 1573px var(--stars), 679px 422px var(--stars),
//     770px 1261px var(--stars), 215px 628px var(--stars), 973px 860px var(--stars), 1825px 1163px var(--stars),
//     619px 779px var(--stars), 1193px 1162px var(--stars), 1654px 583px var(--stars), 753px 1216px var(--stars),
//     817px 1774px var(--stars), 1765px 794px var(--stars), 1474px 1274px var(--stars), 819px 802px var(--stars),
//     740px 1076px var(--stars), 507px 1827px var(--stars), 1505px 377px var(--stars), 1142px 404px var(--stars),
//     420px 727px var(--stars);
// }
// @media (max-width: 768px){
//   header.masthead,.jumbotron {
//   position: absolute;
//   width: 100%;
//   height:100%;
//   padding-top: 150px;
//   color: white;
//   background: var(--star_bg);
//   /* background: linear-gradient(to right, black, red); */
//   background-attachment: fixed;
// }
// }
// `;
function Welcome() {
  useEffect(() => {
    // Add the no-scroll class to the body when the component mounts
    document.body.classList.add('no-scroll');

    // Remove the no-scroll class from the body when the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
  return (
    <div className="bgg">
      {/* <StyledContainer> */}
      <div className="background">
        <header className="masthead waveWrapper waveAnimation" id="headerC">
          <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
          <div className="git-logo">
            <img src={glogo} className="g-logo" alt="imgs" />
            <p className="git-header">ADITYA BAHL</p>
          </div>
          <div className="git-content">
            <div className="git-content-two">
              <p className="g-text">Dive into my world</p>
              <p className="g-text-two">
                Unveiling My Code Journey Where Every Commit Tells a Story!
              </p>
              <p className="g-text-two">
                Scroll for the best experience.
              </p>
              <div className="exploreButton">
                <Explore />
              </div>
            </div>
          </div>
          {/* <div className="waveWrapperInner bgTop">
              <div className="wave waveTop"></div>
            </div>
            <div className="waveWrapperInner bgMiddle">
              <div className="wave waveMiddle"></div>
            </div>
            <div className="waveWrapperInner bgBottom">
              <div className="wave waveBottom"></div>
            </div> */}
        </header >
      </div >
      {/* </StyledContainer> */}
    </div>
  );
}
export default Welcome;
