// import { motion, useMotionValue, useTransform } from "framer-motion";
import React from "react";
import styled from "styled-components";

// import AirJordanImg from "../../assets/images/air-jordan-transparent.png";
// import { CardDetails } from "./CardDetails";

const CardWrapper = styled.div`
  margin-top:-5%;
  width: 100%;
  perspective: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Left = styled.div`
  width: 100%;
  margin-left:-10%;
  perspective: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size:40px;
  @media(max-width:768px){
    position:absolute;
    font-size:34px;
    width:100%;
    margin-top:-100%;
    margin-left:0%;
  }
`;
const Right = styled.div`
  width: 100%;
  font-size:30px;
  perspective: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px){
    position:absolute;
    width:80%;
    font-size:20px;
    margin-top:-20%;
    text-align: center;
  }
`;
const RightBottom = styled.div`
  bottom:2%;
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  @media (max-width: 768px){
    bottom:-45%;
    display:grid;
    .spacee{
      margin-bottom:15%;
      // height:10%;
    }
  }
  
`;
const Button = styled.button`
  margin:10px;
  padding: 10px 16px;
  background-color: #1A44EF;
  width:160px;
  color: #fff;
  // text-transform: uppercase;
  font-size: 16px;
  border: 3px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 100px;
  &:hover {
    background-color: #1332B1;
    color: #fff;
    border: 3px solid transparent;
  }
`;
const Button2 = styled.button`
  margin:10px;
  padding: 10px 16px;
  background-color: transparent;
  width:160px;
  color: #fff;
  // text-transform: uppercase;
  font-size: 16px;
  border: 1px solid white;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 100px;
  &:hover {
    background-color: #1332B1;
    color: #fff;
    border: 3px solid transparent;
  }
`;
const Blank = styled.button`
  height:800px;
  background-color:rgba(0,0,0,0);
  outline:none;
  border:none;
`;
export function ContactSection () {
  const redirectToMail = () => {
    window.open("https://mail.google.com/mail/?view=cm&fs=1&to=adityabahl12345@gmail.com ", '_blank');
  };
  const redirectToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/aditya-bahl-22334819b/", '_blank');
  };
  const redirectToGitHub = () => {
    window.open("https://github.com/AdityaBahl", '_blank');
  };
  // const redirectToYT = () => {
  //   window.open("https://www.youtube.com/@ogideology", '_blank');
  // };
  const redirectToLeetCode = () => {
    window.open("https://leetcode.com/adityabahl/", '_blank');
  };
  const redirectToBMC = () => {
    window.open("https://www.buymeacoffee.com/adityabahl", '_blank');
  };
  const redirectToResume = () => {
    window.open("https://drive.google.com/file/d/1rqoFxs5jYfHvzc7COR52EfkSaWKA_mDt/view?usp=sharing", '_blank');
  };
 return (
    <CardWrapper>
        <Left>
        <h1 style={{fontWeight:"500"}}>Contact Me</h1>
        </Left>
        <Right>
            <p>
            Hey there, adventure seeker! 🚀 Ready to embark on an exciting journey together? Whether it's unleashing creativity on new projects, diving into thrilling part-time gigs, or embracing a full-time adventure, count me in! 🎉 Drop me a line via email, I have my inbox on speed dial! ⚡ Let's skip the delays and dive headfirst into some epic collaborations! 🌟 
            </p>   
           
        </Right>
        {/* <p>adityabahl12345@gmail.com</p>  */}
        <Blank/>
        <RightBottom>
            <Button2 onClick={redirectToLinkedIn}>LinkedIn</Button2>
            <Button2 onClick={redirectToGitHub}>GitHub</Button2>
            {/* <Button onClick={redirectToYT}>Youtube</Button> */}
            <Button2 onClick={redirectToLeetCode}>LeetCode</Button2>
            <div className="spacee"></div>
            <Button2 onClick={redirectToBMC}>Buy Me A Coffee</Button2>
          <Button onClick={redirectToMail}>Send Mail</Button>
            <Button onClick={redirectToResume}>Resume</Button>
            {/* <div className="spacee"></div> */}
           </RightBottom>
    </CardWrapper>
  );
}
