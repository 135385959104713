import { Shapes } from "../pages/Shapes";
import { transition } from "../pages/settings";
import useMeasure from "react-use-measure";
// import { NikeCard } from "../components/nikeCard";
import React, { Suspense, useState } from "react";
import styled from 'styled-components';
import { motion, Variants, Transition } from "framer-motion";
// import { StarIcon } from "../pages/StarIcon";

// style for star button
const Star_StyledContainer = styled.div`
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap");

button {
  --button-star-greyscale: 100%;
  --button-star-contrast: 0%;

  appearance: none;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: #5e5e5e;
  border-radius: 36px;
  outline: none;
  margin: 0;
  padding: 0;
  padding-left: 90px;
  font-family: "Montserrat Alternates";
  font-size: 42px;
  letter-spacing: -2px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.05),
    inset 0px -10px 20px 0px rgba(0, 0, 0, 0.05),
    0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.icon {
  display: block;
  width: 600px;
  height: 300px;
  z-index: 1;
  pointer-events: none;
  transform-origin: 50% 52%;
  filter: grayscale(var(--button-star-greyscale))
    contrast(var(--button-star-contrast));
  opacity: 0.3;
  position: absolute;
  top: -100px;
  left: -240px;
}

.icon canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.label {
  width: 140px;
  padding: 20px 0 22px;
  transform: translateZ(0);
}

.default {
  display: block;
}

.number {
  padding: 30px 36px;
  position: relative;
  transform: translateZ(0);
}

.number:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #e8e8e8;
  opacity: 0.4;
}

.current {
  color: #d9d9d9;
  opacity: 1;
  display: block;
}

.new {
  color: #fed600;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  display: block;
}

.add {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(38px);
  pointer-events: none;
  color: #d0d0db;
  display: block;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
}

*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  display: flex;
  font-family: "Inter", Arial;
  justify-content: center;
  align-items: center;
  background: #f4f5fa;
}

`;
function StarCounter() {
  const [isHover, setIsHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  return (
    <Star_StyledContainer>
      <motion.button
        initial={false}
        animate={[isLiked ? "liked" : "unliked", isHover ? "hover" : "rest"]}
        whileTap="press"
        variants={buttonVariants}
        onHoverStart={() => setIsHover(true)}
        onHoverEnd={() => setIsHover(false)}
        onClick={() => setIsLiked(!isLiked)}
      >
        <motion.div
          className="icon"
          variants={{
            liked: { opacity: 0, transition: iconFadeTransition },
            hover: isLiked
              ? { opacity: 0, transition: iconFadeTransition }
              : { opacity: 1 }
          }}
        >
          <Suspense fallback={null}>
            {/* <StarIcon isHover={isHover} isLiked={isLiked} /> */}
          </Suspense>
        </motion.div>
        <div className="label">
          <motion.span variants={labelTextVariants} className="default">
            Star
            <motion.span variants={successTextVariants} className="success">
              red
            </motion.span>
          </motion.span>
        </div>
        <div className="number">
          <motion.span variants={currentCountVariants} className="current">
            38
          </motion.span>
          <motion.span variants={newCountVariants} className="new">
            39
          </motion.span>
        </div>
      </motion.button>
    </Star_StyledContainer>
  );
}
export default StarCounter;
const iconFadeTransition: Transition = { duration: 0.2, delay: 0.3 };

const buttonVariants: Variants = {
  rest: {
    "--button-star-greyscale": "100%",
    "--button-star-contrast": "0%",
    transition: { duration: 0.7 }
  },
  hover: {
    "--button-star-greyscale": "0%",
    "--button-star-contrast": "100%",
    scale: 1.2,
    y: -8
  },
  press: { scale: 1.1 }
};

const labelTextVariants: Variants = {
  unliked: { x: 24 },
  liked: { x: -46 }
};

const successTextVariants: Variants = {
  unliked: { opacity: 0 },
  liked: { opacity: 1 }
};

const likedTransition: Transition = {
  duration: 0.25,
  delay: 0.3
};

const currentCountVariants: Variants = {
  unliked: { opacity: 1, y: 0, transition: { duration: 0.25 } },
  liked: { opacity: 0, y: -40, transition: likedTransition }
};

const newCountVariants: Variants = {
  unliked: { opacity: 0, y: 40, transition: { duration: 0.25 } },
  liked: { opacity: 1, y: 0, transition: likedTransition }
};
