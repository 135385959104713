import React from "react";
import styled from "styled-components";
import { Marginer } from "../../marginer";
import YTimage from "../../../assets/images/YTimage.png";
// import NikeImg from "../../assets/images/nike-logo.png";
import { useNavigate } from 'react-router-dom';
const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5em 6px 0 6px;
  line-height: 1.4;
`;

const MediumText = styled.span`
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

const SmallText = styled.span`
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
`;

const SpacedHorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BuyButton = styled.button`
  border: 3px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 8px;

  &:hover {
    background-color: transparent;
    color: #fff;
  }
  img{
    height:60px;
  }
`;

const NikeLogo = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 13px;
  }
`;

export function CardDetails(props) {
  const navigate = useNavigate();
  const redirectToGoogle = () => {
    window.open(props.link, '_blank');
    // window.location.href = props.link; // Directly change the window location
    // Alternatively, you can use the history.push method if you are using react-router
    // history.push('https://www.google.com');
  };
  return (
    <DetailsContainer>
      <MediumText>{props.name}</MediumText>
      {/* <SmallText>NIKE</SmallText> */}
      <SpacedHorizontalContainer>
      <SmallText>{props.desc}</SmallText>
        {/* <MediumText>AIR JORDAN 1 MID SE GC</MediumText> */}
        {/* <MediumText>¥856</MediumText> */}
      </SpacedHorizontalContainer>
      <Marginer direction="vertical" margin="1.2em" />
      <SpacedHorizontalContainer>
        {/* <MediumText>YOUR NEXT SHOES</MediumText> */}
        {/* <BuyButton style={{marginLeft:"33%"}} onClick={redirectToGoogle}> */}
          <img style={{"height":"50px", marginLeft:"38%"}}onClick={redirectToGoogle} src={YTimage}/>
        {/* </BuyButton> */}
      </SpacedHorizontalContainer>
      {/* <NikeLogo>
        <img src={NikeImg} />
      </NikeLogo> */}
    </DetailsContainer>
  );
}
